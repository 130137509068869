import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { LookupService } from '../shared/lookup.service';
import { FilterService } from '../shared/filter.service';
import { Subscription } from 'rxjs/Subscription';
import { BaseSearchRequest, FilterItem } from '../shared/filter-item.model';
import { NavigateWithFiltersService } from '../shared/navigate-with-filters.service';
import { DateUtils } from '../shared/date-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { InventoryComponent } from '../inventory/inventory.component';
import { FilterODDropDownsComponent } from '../shared/components/filter-od-drop-downs/filter-od-drop-downs.component';
import {ForecastComponent} from '../forecast/forecast.component';

@Component({
    selector: 'app-sidebar-nav',
    templateUrl: './sidebar-nav.component.html',
    styleUrls: ['./sidebar-nav.component.scss']
})
export class SidebarNavComponent implements OnInit {
    static selectedInventoryGridColumns: any[];
    @ViewChild('rsidMultiSelect') private rsidMultiSelect: any;
    @ViewChild('odSelector') private odSelector: FilterODDropDownsComponent;
    private selectedColValues: any[];
    private selectedFcstColValues: any[];
    @Input() set parentPopupContainer(container: ViewContainerRef) {
        this.popupSettings = { appendTo: container };
    }
    private viewFilterSubscription: Subscription;
    private filterLayoutSubscription: Subscription;
    private viewFilter: any;
    public popupSettings: any;
    public width = '250';
    public opened: boolean;
    public allowToggle = false;
    public classData: FilterItem = new FilterItem();
    public viewTypeData: FilterItem = new FilterItem();
    public portfolioData: FilterItem = new FilterItem();
    public journeyTypeData: FilterItem = new FilterItem();
    public daypartData: FilterItem = new FilterItem();
    public dayOfWeekData: FilterItem = new FilterItem();
    public rsidData: FilterItem = new FilterItem();
    public startPeriod: FilterItem = new FilterItem();
    public endPeriod: FilterItem = new FilterItem();
    public statusTypeData: FilterItem = new FilterItem();
    public startDateRange: number;
    public endDateRange: number;
    public viewLevelSwitch = true;
    public dateSwitch = true;
    public startDate: Date;
    public endDate: Date;
    public groupSize: number;
    public filterTimePickerValue = {
        timeType: 'departure',
        startTime: null,
        endTime: null
    };
    public filterLayout: any;
    public filtersLoading = false;
    private tempInterval: any;

    constructor(private lookupSvc: LookupService, private filterService: FilterService, private navService: NavigateWithFiltersService) {
        this.viewFilterSubscription = this.filterService.selectedFilterView$.subscribe(viewFilter => {
            this.populateFilters(viewFilter);
            this.filterService.doSearch(this.buildSearchRequest());
        });

        this.filterLayoutSubscription = this.filterService.filterLayoutView$.subscribe(this.filterLayoutChanging.bind(this));

        navService.filtersChangeTriggered$.subscribe(this.resetFiltersAndSearch.bind(this));
    }

    ngOnInit() {
        this.filtersLoading = true;
        this.loadFilters();
    }

    public filterLayoutChanging(filterLayout) {
        this.filterLayout = filterLayout;
        const skipApplyFilter = 'Y' === localStorage.getItem('skipFiltersOnNavigate');
        if (skipApplyFilter) {
            localStorage.removeItem('skipFiltersOnNavigate');
        }

        if (this.filterLayout.screenName === 'inventory') {
            this.clearFilterValues(this.filterLayout.clear);
        }

        if (!skipApplyFilter && this.canApplyFilters()) {
            // Run the search
            this.filterService.doSearch(this.buildSearchRequest());
        }
    }

    public onSearchClicked(): void {
        this.filterService.doSearch(this.buildSearchRequest(true));
    }

    public clearFilter(filter: FilterItem): void {
        if (filter) {
            filter.clear();
        }
    }

    public onClearClicked(): void {
        this.groupSize = null;
        this.startDateRange = null;
        this.endDateRange = null;
        this.startDate = null;
        this.endDate = null;

        this.clearFilter(this.dayOfWeekData);
        this.clearFilter(this.portfolioData);
        this.clearFilter(this.classData);
        this.clearFilter(this.daypartData);
        this.clearFilter(this.journeyTypeData);
        this.clearFilter(this.dayOfWeekData);
        this.clearFilter(this.rsidData);
        this.clearFilter(this.statusTypeData);

        if (this.odSelector) {
            this.clearFilter(this.odSelector.destinationData);
            this.clearFilter(this.odSelector.originData);
            this.odSelector.originData.selectedValue = null;
            this.odSelector.destinationData.selectedValue = null;
        }

        if (this.filterTimePickerValue) {
            this.filterTimePickerValue.startTime = null;
            this.filterTimePickerValue.endTime = null;
        }
    }

    public checkHiddenFilter(filterName: string): boolean {
        if (this.filterLayout) {
            return !(this.filterLayout.hidden.findIndex(f => f.name === filterName) >= 0);
        }
    }

    public isToolbarHidden(): boolean {
        return this.filterLayout?.toolbarHidden;
    }

    public buildViewFilter(): any {
        if (window.location.href.indexOf('/inventory') > -1) {
            this.selectedColValues = JSON.parse(localStorage.getItem('selectedInventoryGridColumns'));
        } else {
            this.selectedColValues = JSON.parse(localStorage.getItem('invGridSelectedColumns'));
        }
        if (window.location.href.indexOf('/forecast') > -1) {
            this.selectedFcstColValues = JSON.parse(localStorage.getItem('selectedForecastGridColumns'));
        } else {
            this.selectedFcstColValues = JSON.parse(localStorage.getItem('fcstGridSelectedColumns'));
        }
        const viewFilter = {
            name: '',
            viewConfiguration: [
                {
                    id: 'classData',
                    name: 'Class Type',
                    values: this.classData.selectedValues
                },
                {
                    id: 'viewTypeData',
                    name: 'View Type',
                    values: this.viewTypeData.selectedValues
                },
                {
                    id: 'portfolioData',
                    name: 'Portfolio',
                    values: this.portfolioData.selectedValues
                },
                {
                    id: 'journeyTypeData',
                    name: 'Journey Type',
                    values: this.journeyTypeData.selectedValues
                },
                {
                    id: 'daypartData',
                    name: 'Daypart',
                    values: this.daypartData.selectedValues
                },
                {
                    id: 'dayOfWeekData',
                    name: 'Day of Week',
                    values: this.dayOfWeekData.selectedValues
                },
                {
                    id: 'rsidData',
                    name: 'RSID',
                    values: this.rsidData.selectedValues
                },
                {
                    id: 'startPeriod',
                    name: 'Start Period',
                    values: this.startPeriod.selectedValues
                },
                {
                    id: 'endPeriod',
                    name: 'End Period',
                    values: this.endPeriod.selectedValues
                },
                {
                    id: 'startDateRange',
                    name: 'Start Date Range',
                    values: this.startDateRange
                },
                {
                    id: 'endDateRange',
                    name: 'End Date Range',
                    values: this.endDateRange
                },
                {
                    id: 'startDate',
                    name: 'Start Date',
                    values: this.startDate
                },
                {
                    id: 'endDate',
                    name: 'End Date',
                    values: this.endDate
                },
                {
                    id: 'viewLevelSwitch',
                    name: 'View Switch',
                    values: this.viewLevelSwitch
                },
                {
                    id: 'dateSwitch',
                    name: 'Date Switch',
                    values: this.dateSwitch
                },
                {
                    id: 'statusTypeData',
                    name: 'Status Type',
                    values: this.statusTypeData.selectedValues
                },
                {
                    id: 'invGridSelectedColumns',
                    name: 'Selected Columns',
                    values: this.selectedColValues
                },
                {
                    id: 'fcstGridSelectedColumns',
                    name: 'Forecast Selected Columns',
                    values: this.selectedFcstColValues
                }
            ]
        };

        if (this.odSelector) {
            viewFilter.viewConfiguration.push(...this.odSelector.getCurrentFilterConfig());
        }
        if (window.location.href.indexOf('/inventory') > -1) {
            localStorage.setItem('invGridSelectedColumns', localStorage.getItem('selectedInventoryGridColumns'));
        }
        if (window.location.href.indexOf('/forecast') > -1) {
            localStorage.setItem('fcstGridSelectedColumns', localStorage.getItem('selectedForecastGridColumns'));
        }
        this.viewFilter = viewFilter;
        return viewFilter;
    }

    public populateFilters(viewFilter): void {
        this.portfolioData.selectedValues = viewFilter.viewConfiguration.find(fi => fi.id === 'portfolioData').values;

        this.odSelector.setValues(this.getFilterValueFromConfig(viewFilter, 'originData'),
            this.getFilterValueFromConfig(viewFilter, 'destinationData'));

        this.journeyTypeData.selectedValues = viewFilter.viewConfiguration.find(fi => fi.id === 'journeyTypeData').values;
        this.daypartData.selectedValues = viewFilter.viewConfiguration.find(fi => fi.id === 'daypartData').values;
        this.dayOfWeekData.selectedValues = viewFilter.viewConfiguration.find(fi => fi.id === 'dayOfWeekData').values;
        this.rsidData.selectedValues = viewFilter.viewConfiguration.find(fi => fi.id === 'rsidData').values;
        this.viewTypeData.selectedValues = viewFilter.viewConfiguration.find(fi => fi.id === 'viewTypeData').values;
        this.startDateRange = viewFilter.viewConfiguration.find(fi => fi.id === 'startDateRange').values;
        this.endDateRange = viewFilter.viewConfiguration.find(fi => fi.id === 'endDateRange').values;
        this.viewLevelSwitch = viewFilter.viewConfiguration.find(fi => fi.id === 'viewLevelSwitch').values;
        this.dateSwitch = viewFilter.viewConfiguration.find(fi => fi.id === 'dateSwitch').values;
        this.classData.selectedValues = viewFilter.viewConfiguration.find(fi => fi.id === 'classData').values;
        this.startPeriod.selectedValues = viewFilter.viewConfiguration.find(fi => fi.id === 'startPeriod').values;
        this.endPeriod.selectedValues = viewFilter.viewConfiguration.find(fi => fi.id === 'endPeriod').values;
        this.statusTypeData.selectedValues = viewFilter.viewConfiguration.find(fi => fi.id === 'statusTypeData').values;
        // Check if the filter has the inventory grid selected columns in it and load the data to inventory metrics selected values.
        if (viewFilter.viewConfiguration.find(fi => fi.id === 'invGridSelectedColumns')) {
            localStorage.setItem('invGridSelectedColumns', JSON.stringify(viewFilter.
                viewConfiguration.find(fi => fi.id === 'invGridSelectedColumns').values));
            if (window.location.href.indexOf('/inventory') > -1) {
                InventoryComponent.loadSelectedColumns(viewFilter.viewConfiguration.find(fi => fi.id === 'invGridSelectedColumns').values);
            }
        } else {
            localStorage.setItem('invGridSelectedColumns', JSON.stringify([]));
            localStorage.setItem('selectedInventoryGridColumns', JSON.stringify([]));
        }
        if (viewFilter.viewConfiguration.find(fi => fi.id === 'fcstGridSelectedColumns')) {
            localStorage.setItem('fcstGridSelectedColumns', JSON.stringify(viewFilter.
                viewConfiguration.find(fi => fi.id === 'fcstGridSelectedColumns').values));
            if (window.location.href.indexOf('/forecast') > -1) {
                ForecastComponent.loadSelectedColumns(viewFilter.viewConfiguration.find(fi => fi.id === 'fcstGridSelectedColumns').values);
            }
        } else {
            localStorage.setItem('fcstGridSelectedColumns', JSON.stringify([]));
            localStorage.setItem('selectedForecastGridColumns', JSON.stringify([]));
        }
        const dateOptionSwitch = viewFilter.viewConfiguration.find(fi => fi.id === 'dateOptionSwitch');
        const today = new Date();
        if (this.dateSwitch && dateOptionSwitch && dateOptionSwitch.values) {
            this.startDate = moment(viewFilter.viewConfiguration.find(fi => fi.id === 'startDate').values).toDate();
            this.endDate = moment(viewFilter.viewConfiguration.find(fi => fi.id === 'endDate').values).toDate();
            this.startDateRange = DateUtils.daysDiff(today, this.startDate);
            this.endDateRange = DateUtils.daysDiff(today, this.endDate);
        } else {
            this.startDate = DateUtils.addDays(today, this.startDateRange);
            this.endDate = DateUtils.addDays(today, this.endDateRange);
        }
    }

    public dropdownTagMapper(tags: any[]): any[] {
        return tags.length < 3 ? tags : [tags];
    }

    private resetFiltersAndSearch(req: BaseSearchRequest) {
        this.portfolioData.selectedValues = !!req.portfolio ? req.portfolio.map(p =>
            ({ id: p, name: p })) : this.portfolioData.selectedValues;
        this.odSelector.clearAndSetValues(req);
        this.journeyTypeData.selectedValues = !!req.journeyType ? req.journeyType : this.journeyTypeData.selectedValues;
        this.daypartData.selectedValues = !!req.daypart ? req.daypart : this.daypartData.selectedValues;
        this.dayOfWeekData.selectedValues = !!req.dayOfWeek ? req.dayOfWeek : this.dayOfWeekData.selectedValues;
        this.rsidData.selectedValues = req.rsid ? req.rsid.map(v => ({ id: v, name: v })) : this.rsidData.selectedValues;
        this.viewTypeData.selectedValues = !!req.viewType ? this.viewTypeData.findByName(req.viewType) : this.viewTypeData.selectedValues;
        this.classData.selectedValues = this.classData.selectedValues !== null && this.classData.selectedValues !== undefined ?
            this.classData.selectedValues : this.classData.findById('standard');
        this.startPeriod.selectedValues = [];
        this.endPeriod.selectedValues = [];
        this.statusTypeData.selectedValues = !!req.statusType ?
            [this.statusTypeData.findByName(req.statusType)] : this.statusTypeData.selectedValues;

        this.dateSwitch = true;
        this.startDate = req.startDate ? req.startDate : this.startDate;
        this.endDate = req.endDate ? req.endDate : this.endDate;
        this.startDateRange = DateUtils.daysDiff(new Date(), this.startDate);
        this.endDateRange = DateUtils.daysDiff(new Date(), this.endDate);

        this.filterService.doSearch(this.buildSearchRequest());
    }

    public displayToggle(): boolean {
        return this.allowToggle;
    }

    public rsidValueNormalizer = (text$: Observable<string>) => text$.pipe(map((text: string) => {
        // search for matching item to avoid duplicates

        // search in values
        const matchingValue: any = this.rsidData.selectedValues.find((item: any) => {
            return item.name.toLowerCase() === text.toLowerCase();
        });

        if (matchingValue) {
            return matchingValue; // return the already selected matching value and the component will remove it
        }

        // search in data
        const matchingItem: any = this.rsidData.allValues.find((item: any) => {
            return item.name.toLowerCase() === text.toLowerCase();
        });

        if (matchingItem) {
            return matchingItem;
        } else {
            return {
                id: text,
                name: text
            };
        }
    }))

    private loadFilters(): void {
        this.portfolioData.loadAllValues(this.lookupSvc.loadPortfolio());
        this.journeyTypeData.loadAllValues(this.lookupSvc.loadJourneyTypes());
        this.daypartData.loadAllValues(this.lookupSvc.loadDayparts());
        this.dayOfWeekData.loadAllValues(this.lookupSvc.loadDaysOfWeek());
        this.rsidData.loadAllValues(this.lookupSvc.loadRSIDs());

        const viewTypesLoader = this.lookupSvc.loadViewTypes();
        this.viewTypeData.loadAllValues(viewTypesLoader);
        // Also, when view-types are loaded - default 'Daily' as selected value
        viewTypesLoader.subscribe(result => {
            this.viewTypeData.selectedValues = result.data.find(o => (o.name === 'Daily'));
        });

        this.classData.loadAllValues(this.lookupSvc.loadClassTypes());
        this.startPeriod.loadAllValues(this.lookupSvc.loadPeriods(true)); // , this.periodSorter);
        this.endPeriod.loadAllValues(this.lookupSvc.loadPeriods(false)); // , this.periodSorter);
        this.statusTypeData.loadAllValues(this.lookupSvc.getStatusTypes());
        this.startDateRange = 0;
        this.endDateRange = 0;
        // this.viewTypeData.selectedValues = !!req.viewType ? this.viewTypeData.findByName(req.viewType)
        // now wait for all above to load to set this.filtersLoading
        this.tempInterval = setInterval(() => {
            if (this.checkFiltersLoaded()) {
                clearInterval(this.tempInterval);
                this.filtersLoading = false;
            }
        }, 500);
    }
    private checkFiltersLoaded() {
        if (!this.portfolioData.valuesLoaded || !this.odSelector.valuesLoaded() ||
            !this.journeyTypeData.valuesLoaded || !this.daypartData.valuesLoaded || !this.dayOfWeekData.valuesLoaded ||
            !this.rsidData.valuesLoaded || !this.viewTypeData.valuesLoaded || !this.classData.valuesLoaded ||
            !this.startPeriod.valuesLoaded || !this.endPeriod.valuesLoaded || !this.statusTypeData.valuesLoaded) {
            return false;
        }
        return true;
    }

    private buildSearchRequest(isNewSearch = false): BaseSearchRequest {
        const searchRequest = new BaseSearchRequest();
        if (this.dateSwitch) {
            searchRequest.startDate = this.startDate;
            searchRequest.endDate = this.endDate;
        } else {
            searchRequest.startDate = this.startPeriod.selectedValues.id;
            searchRequest.endDate = this.endPeriod.selectedValues.id;
        }

        if (['dashboard', 'forecast'].indexOf(this.filterLayout.screenName) >= 0) {
            searchRequest.viewLevel = this.viewLevelSwitch; // OD: true, Leg: False
            searchRequest.viewType = this.viewTypeData.selectedValues.name;
            searchRequest.daypart = this.daypartData.selectedValues.map(dp => dp.id);
        }

        if (['inventory'].indexOf(this.filterLayout.screenName) >= 0) {
            searchRequest.recordStatus = this.statusTypeData.selectedValues.map(st => st.id);
        }

        if (['dashboard', 'forecast', 'business-rules'].indexOf(this.filterLayout.screenName) >= 0) {
            searchRequest.journeyType = this.journeyTypeData.selectedValues.map(jt => jt.id);
        }
        if (['group-sales'].indexOf(this.filterLayout.screenName) >= 0) {
            searchRequest.time = Object.assign({}, this.filterTimePickerValue);
            searchRequest.time.startTime = (searchRequest.time.startTime as Date).toLocaleTimeString();
            searchRequest.time.endTime = (searchRequest.time.endTime as Date).toLocaleTimeString();
            searchRequest.groupSize = this.groupSize;
        }
        searchRequest.passengerClass = this.classData.selectedValues.id;
        searchRequest.portfolio = this.portfolioData.selectedValues.map(port => port.id);

        if (this.odSelector) {
            const selectedODs = this.odSelector.getSelectedValues(this.filterLayout?.journeyFilterConfig);
            searchRequest.origin = selectedODs.origin;
            searchRequest.destination = selectedODs.destination;
        }

        searchRequest.dayOfWeek = this.dayOfWeekData.selectedValues.map(dow => dow.id);
        searchRequest.rsid = this.handleWildCardFilterValues(this.rsidData.allValues,
            this.rsidData.selectedValues).map(rsid => rsid.id);
        searchRequest.startDateRange = this.startDateRange;
        searchRequest.endDateRange = this.endDateRange;
        searchRequest.isNewSearch = isNewSearch;

        return searchRequest;
    }

    handleFilterStart(value) {
        this.startPeriod.filterItems(value);
    }

    handleFilterEnd(value) {
        this.endPeriod.filterItems(value);
    }

    startPeriodChange(value: any) {
        // tslint:disable-next-line:radix
        this.endPeriod.filterItemsByFn((s) => s.id > value.id, true);
    }

    canApplyFilters() {
        const notBusinessRulesScreen = (this.filterLayout !== undefined &&
            this.filterLayout.screenName !== 'business-rules');
        const notGroupSalesScreen = (this.filterLayout !== undefined &&
            this.filterLayout.screenName !== 'group-sales');
        if (!this.classData.selectedValues.id && notBusinessRulesScreen) {
            return false;
        }
        if ((!this.portfolioData.selectedValues || this.portfolioData.selectedValues.length === 0) &&
            (notBusinessRulesScreen && notGroupSalesScreen)) {
            return false;
        }
        if (!notGroupSalesScreen && (this.filterTimePickerValue.startTime === null ||
            this.filterTimePickerValue.endTime === null)) {
            return false;
        }
        if (!notGroupSalesScreen && (this.groupSize === null || this.groupSize === undefined)) {
            return false;
        }
        if (!notGroupSalesScreen && !this.odSelector.isValid()) {
            return false;
        }
        if (this.dateSwitch && (!this.startDate || !this.endDate) && notBusinessRulesScreen) {
            return this.startDateRange !== undefined && this.endDateRange !== undefined;
        }
        if (!this.dateSwitch && (!this.startPeriod.selectedValues.id || !this.endPeriod.selectedValues.id) &&
            notBusinessRulesScreen) {
            return false;
        }
        if ((this.filterLayout !== undefined && this.filterLayout.screenName === 'dashboard') &&
            (!this.viewTypeData.selectedValues || !this.viewTypeData.selectedValues.name)) {
            return false;
        }
        return true;
    }

    canClearFilters() {
        if (this.portfolioData.selectedValues && this.portfolioData.selectedValues.length > 0) {
            return true;
        }
        if (this.classData) {
            if (Array.isArray(this.classData.selectedValues)) {
                if (this.portfolioData.selectedValues.length > 0) {
                    return true;
                }
            } else {
                return true;
            }
        }
        if (this.startDate || this.endDate) {
            return true;
        }
        if (this.daypartData && this.daypartData.selectedValues.length > 0) {
            return true;
        }
        if (this.journeyTypeData && this.journeyTypeData.selectedValues.length > 0) {
            return true;
        }
        if (this.dayOfWeekData && this.dayOfWeekData.selectedValues.length > 0) {
            return true;
        }
        if (this.rsidData && this.rsidData.selectedValues.length > 0) {
            return true;
        }
        if (this.statusTypeData && this.statusTypeData.selectedValues.length > 0) {
            return true;
        }
        if (this.odSelector) {
            if (this.odSelector.destinationData && this.odSelector.destinationData.selectedValues.length > 0) {
                return true;
            }

            if (this.odSelector.destinationData.selectedValue) {
                return true;
            }

            if (this.odSelector.originData && this.odSelector.originData.selectedValues.length > 0) {
                return true;
            }

            if (this.odSelector.originData.selectedValue) {
                return true;
            }
        }
        if (this.filterTimePickerValue) {
            if (this.filterTimePickerValue.startTime !== null || this.filterTimePickerValue.endTime !== null) {
                return true;
            }
        }
        if (this.groupSize) {
            return true;
        }
    }

    onStartDateChange(newDt: Date) {
        // update startDateRange
        this.startDateRange = DateUtils.daysDiff(new Date(), newDt);
    }

    onEndDateChange(newDt: Date) {
        // update startDateRange
        this.endDateRange = DateUtils.daysDiff(new Date(), newDt);
    }

    onStartDateRangeChange(newVal: number) {
        this.startDate = DateUtils.addDays(new Date(), newVal);
        // console.log('Start Date Range Changed to ' + newVal);
    }

    onEndDateRangeChange(newVal: number) {
        this.endDate = DateUtils.addDays(new Date(), newVal);
        // console.log('Start Date Range Changed to ' + newVal);
    }

    public rsidMultiSelectChange(): void {
        // TODO: Remove hack once KendoUI fixes bug with multi select
        // https://github.com/telerik/kendo-angular/issues/2753
        setTimeout(() => {
            this.rsidMultiSelect.focus();
        });
    }

    private clearFilterValues(filterNames: string[]): void {
        filterNames.forEach(filterName => {
            if (filterName in this) {
                this[filterName].selectedValues = [];
            }
        });
    }

    private getFilterValueFromConfig(viewFilter: any, filterId: string): any {
        return viewFilter.viewConfiguration.find(fi => fi.id === filterId).values;
    }

    private handleWildCardFilterValues(allData: any, selectedData: any): any {
        const wildCards = selectedData.filter(item => item.id.includes('*'));
        if (!wildCards) {
            return [];
        }
        const nonWild = selectedData.filter(item => !item.id.includes('*'));
        const filterBy = str => allData.filter(
            item => {
                const escapeRegex = (str$) => str$.replace(/\*/g, '\\$1');
                return new RegExp('^' + str.split('*').map(escapeRegex).join('.*') + '$').test(item.id);
            }
        );

        let records = [];
        wildCards.forEach(wildCard => {
            records = records.concat(filterBy(wildCard.id));
        });
        records = records.concat(nonWild);

        // If wild card records are provided, but non could be deterimied from the existing data
        // add dummy record to prevent filtering on whole data set.
        if (wildCards.length > 0 && records.length === 0) {
            records = ['NO_RECORDS'];
        }

        return [...new Set(records)];
    }
}
