<div class="main-side-menu">
    <div class="side-menu-section">
        <ul class="nav side-menu">
            <li class="filter-menu-item menu-item">
                <a (click)="toggleSideNav()" title="Filter">
                    <mat-icon>menu</mat-icon>
                </a>
            </li>
            <li class="menu-item">
                <a routerLink="/dashboard" routerLinkActive="active-link" title="Dashboard"
                   #rl1="routerLinkActive"
                   [class.active-menu]="rl1.isActive">
                    <mat-icon class="menu-icon">
                        dashboard
                    </mat-icon>
                </a>
            </li>
            <li class="menu-item">
                <a routerLink="/forecast" routerLinkActive="active-link" title="Forecast"
                   #rl2="routerLinkActive"
                   [class.active-menu]="rl2.isActive">
                    <mat-icon class="menu-icon">
                        multiline_chart
                    </mat-icon>
                </a>
            </li>
            <li class="menu-item">
                <a routerLink="/inventory" routerLinkActive="active-link" title="Inventory"
                   #rl3="routerLinkActive"
                   [class.active-menu]="rl3.isActive">
                    <mat-icon class="menu-icon">
                        train
                    </mat-icon>
                </a>
            </li>
            <li [hidden] = "showGroupSales" class="menu-item">
                <a routerLink="/group-sales" routerLinkActive="active-link" title="Group Sales"
                   #rl5="routerLinkActive"
                   [class.active-menu]="rl5.isActive">
                    <mat-icon class="menu-icon">
                        group
                    </mat-icon>
                </a>
            </li>
            <li class="menu-item">
                <a routerLink="/status" routerLinkActive="active-link" title="Status"
                   #rl6="routerLinkActive"
                   [class.active-menu]="rl6.isActive">
                    <mat-icon class="menu-icon">
                        info
                    </mat-icon>
                </a>
            </li>
        </ul>
    </div>
    <div class="side-menu-section">
        <ul class="nav side-menu">
            <li class="menu-item">
                <a title="Notifications">
                    <app-notification-menu></app-notification-menu>
                </a>
            </li>
            <li class="menu-item">
                <app-settings-menu></app-settings-menu>
            </li>
            <li class="user-container menu-item">
                <app-user-menu></app-user-menu>
            </li>
        </ul>
    </div>
</div>