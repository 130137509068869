import {Injectable} from '@angular/core';
import {HeatmapModel} from '../models/heatmap.model';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, Subject} from 'rxjs';
import {UrlStore} from '../../shared/url-store';
import {map} from 'rxjs/operators';
import {DateUtils} from '../../shared/date-utils';
import {of} from 'rxjs/internal/observable/of';

@Injectable({
    providedIn: 'root'
})
export class HeatmapService {
    private menuClickSubject = new Subject<any>();

    heatmapMenuClick$ = this.menuClickSubject.asObservable();

    constructor(private http: HttpClient) {
    }

    public getHeatmapData(filterOptions: any): Observable<HeatmapModel[]> {
        return this.http.post<HeatmapModel[]>(
            `${UrlStore.api.inventory.heatmap}`,
            {
                ...filterOptions,
                startDate: moment(filterOptions.startDate).format('YYYY-MM-DD'),
                endDate: moment(filterOptions.endDate).format('YYYY-MM-DD')
            }
        ).pipe(map(resp => {
            resp.forEach(item => {
                item.dateLabel = DateUtils.convertToLocaleDateString(item.effDepartureDate as string);
                return item;
            });
            return resp;
        }));
    }

    public getAllHeatmapData(heatMapFilters: any[]): Observable<any> {
        const heatmapFilterOptions = {};
        heatMapFilters.forEach(heatmapFilter => {
            heatmapFilterOptions[heatmapFilter.tabId] = this.getHeatmapData(heatmapFilter);
        });
        return forkJoin({...heatmapFilterOptions});
    }

    public getHeatmapMetricOptions(): Observable<any> {
        return of({
            data: [
                {id: 'capacityConstraint', name: 'Capacity Constraints'},
                {id: 'pacing', name: 'Pacing'},
                {id: 'revenueOpportunity', name: 'Revenue Opportunity'},
                {id: 'walkUpOverride', name: 'Walk-Up Override'}
            ]
        });
    }

    public menuClick(event: any): void {
        this.menuClickSubject.next(event);
    }

}
