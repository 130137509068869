import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {BaseSearchRequest} from './filter-item.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment as env} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    private filterOptions = new Subject<BaseSearchRequest>();
    private selectedView = new Subject<string>();
    private selectedFilterView = new Subject<any>();
    private filterLayoutView = new Subject<any>();
    private filterExpand = new Subject<boolean>();
    public selectedViewName: string;
    public isFilterExpanded = false;
    private currentFilter: BaseSearchRequest;

    // observable streams
    filterTriggered$ = this.filterOptions.asObservable();
    selectedViewTriggered$ = this.selectedView.asObservable();
    selectedFilterView$ = this.selectedFilterView.asObservable();
    filterLayoutView$ = this.filterLayoutView.asObservable();
    filterExpand$ = this.filterExpand.asObservable();

    constructor(private http: HttpClient) {
    }

    public doSearch(searchRequest: BaseSearchRequest): void {
        this.currentFilter = searchRequest;
        this.filterOptions.next(searchRequest);
    }

    public getCurrentFilter(): BaseSearchRequest {
        return this.currentFilter;
    }

    public toggleFilterExpand(isExpanded: boolean): void {
        this.isFilterExpanded = isExpanded;
        this.filterExpand.next(isExpanded);
    }

    public selectFilterView(filterViewName: string): void {
        this.selectedViewName = filterViewName;
        this.selectedView.next(filterViewName);
    }

    public saveFilterView(viewFilter: any): Observable<any> {
        this.selectFilterView(viewFilter.name);
        return this.http.post(`${env.apiBaseUrl}/dim_lookup/filter_views`,
            viewFilter);
    }

    public loadFilterView(filterView): any {
        this.selectFilterView(filterView.name);
        this.selectedFilterView.next(filterView);
    }

    public getFilterViewName(): string {
        return this.selectedViewName ? this.selectedViewName : '';
    }

    public updateFilterLayout(filterLayout: any): void {
        this.filterLayoutView.next(filterLayout);
    }

    public loadAllFilterViews(): Observable<any> {
        return this.http.get(`${env.apiBaseUrl}/dim_lookup/filter_views`);
    }

    public deleteFilterView(filterName: string): Observable<any> {
        const params = new HttpParams().set('filterName', filterName);
        return this.http.delete(`${env.apiBaseUrl}/dim_lookup/filter_views`,
            {
                params
            });
    }

    public favoriteFilterView(filterName: string, isFavorite: boolean): Observable<any> {
        const favoriteFilter = { filterName, isFavorite };
        return this.http.patch(`${env.apiBaseUrl}/dim_lookup/filter_views`, favoriteFilter);
    }

}
