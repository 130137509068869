<mat-card class="ra-fieldset-card ra-card" [style.height.px]="rowHt + 20">
  <mat-card-header class="chart-card-header">
      <mat-card-title class="chart-title">
          Top 20 Booking Changes
      </mat-card-title>
      <div class="select-container">
          <kendo-dropdownlist [data]="daysDropDownOptions.allValues"
                              [(value)]="daysDropDownOptions.selectedValues"
                              [popupSettings]="{appendTo: 'component'}"
                              [textField]="'name'"
                              [valueField]="'id'"
                              (valueChange)="onDaysDropDownChange($event)"></kendo-dropdownlist>
      </div>
  </mat-card-header>
  <mat-card-content class="chart-card-content">
    <kendo-chart class="top-bkg-chart" [popupSettings]="chartPopupSettings"
                 (seriesClick)="onSeriesClick($event)">
      <kendo-chart-legend position="top"
                          orientation="horizontal"></kendo-chart-legend>
      <kendo-chart-value-axis>
        <kendo-chart-value-axis-item
          [majorGridLines]="{ visible: true }"
          [title]="{ text: 'Booking Changes' }"
        ></kendo-chart-value-axis-item>
      </kendo-chart-value-axis>
      <kendo-chart-category-axis>
        <kendo-chart-category-axis-item [majorGridLines]="{ visible: false }">
          <kendo-chart-category-axis-item-labels
            [visible]="false"
          ></kendo-chart-category-axis-item-labels>
        </kendo-chart-category-axis-item>
      </kendo-chart-category-axis>
      <kendo-chart-series>
        <kendo-chart-series-item
          [data]="topBkgChChartData"
          [name]="chartConfig.config.metric.individualBkg.name"
          [stack]="'Bookings'"
          [color]="chartConfig.config.metric.individualBkg.style.color"
          [categoryField]="'key'"
          [field]="'indivBkg'"
        >
          <kendo-chart-series-item-tooltip [background]="'#FFFFFF'">
            <ng-template let-value="dataItem">
              <div class="tooltip-container" (click)="onSeriesClick({dataItem: value})">
                  <div class="tooltip-row">
                      <div class="tooltip-label">Service:</div>
                      <div class="tooltip-value">{{value.rsid}}</div>
                  </div>
                  <div class="tooltip-row">
                      <div class="tooltip-label">Journey:</div>
                      <div class="tooltip-value">{{value.od}}</div>
                  </div>
                  <div class="tooltip-row">
                      <div class="tooltip-label">Departure Date:</div>
                      <div class="tooltip-value">{{value.effDt}}</div>
                  </div>
                  <div class="tooltip-row">
                      <div class="tooltip-label">Individual Bookings:</div>
                      <div class="tooltip-value">{{value.indivBkg}}</div>
                  </div>
              </div>
            </ng-template>
          </kendo-chart-series-item-tooltip>
        </kendo-chart-series-item>
        <kendo-chart-series-item
          [data]="topBkgChChartData"
          [name]="chartConfig.config.metric.groupBkg.name"
          [stack]="'Bookings'"
          [color]="chartConfig.config.metric.groupBkg.style.color"
          [categoryField]="'key'"
          [field]="'groupBkg'"
        >
          <kendo-chart-series-item-tooltip [background]="'#FFFFFF'">
            <ng-template let-value="dataItem">
              <div class="tooltip-container" (click)="onSeriesClick({dataItem: value})">
                  <div class="tooltip-row">
                      <div class="tooltip-label">Service:</div>
                      <div class="tooltip-value">{{value.rsid}}</div>
                  </div>
                  <div class="tooltip-row">
                      <div class="tooltip-label">Journey:</div>
                      <div class="tooltip-value">{{value.od}}</div>
                  </div>
                  <div class="tooltip-row">
                      <div class="tooltip-label">Departure Date:</div>
                      <div class="tooltip-value">{{value.effDt | date: 'shortDate'}}</div>
                  </div>
                  <div class="tooltip-row">
                      <div class="tooltip-label">Group Bookings:</div>
                      <div class="tooltip-value">{{value.groupBkg}}</div>
                  </div>
              </div>
            </ng-template>
          </kendo-chart-series-item-tooltip>
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart>
  </mat-card-content>
</mat-card>
