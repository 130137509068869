import {Injectable, ViewContainerRef} from '@angular/core';
import {DialogService} from '@progress/kendo-angular-dialog';
import {
    BulkInventoryOverrideComponent
} from '../components/bulk-inventory-override/bulk-inventory-override.component';
import {
    SingleInventoryOverrideComponent
} from '../components/single-inventory-override/single-inventory-override.component';

@Injectable({
    providedIn: 'root'
})
export class OverrideDialogService {

    constructor(private dialogService: DialogService) {
    }

    public openBulkDialog(request: any, container: ViewContainerRef) {
        const dialogRef = this.dialogService.open({
            width: 400,
            appendTo: container,
            content: BulkInventoryOverrideComponent
        });
        const bulkDialog = dialogRef.content.instance as BulkInventoryOverrideComponent;
        bulkDialog.bulkRequest = request;
        return bulkDialog;
    }

    public openSingleDialog(request: any, container: ViewContainerRef): SingleInventoryOverrideComponent {
        const dialogRef = this.dialogService.open({
            width: 910,
            height: 755,
            appendTo: container,
            content: SingleInventoryOverrideComponent
        });
        const singleDialog = dialogRef.content.instance as SingleInventoryOverrideComponent;
        singleDialog.dialogRequest = request;
        return singleDialog;
    }

}
