import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {FilterView, SaveFilterRequest} from './filter-view.model';

@Injectable({
  providedIn: 'root'
})
export class FilterToolbarService {
private deleteFilterSource = new Subject<string>();
  private favoriteFilterSource = new Subject<FilterView>();
  private loadFilterSource = new Subject<FilterView>();
  private saveFilterSource = new Subject<SaveFilterRequest>();
  private setPinnedFilterSource = new Subject<FilterView>();

  deleteFilter$ = this.deleteFilterSource.asObservable();
  favoriteFilter$ = this.favoriteFilterSource.asObservable();
  loadFilter$ = this.loadFilterSource.asObservable();
  saveFilter$ = this.saveFilterSource.asObservable();
  setPinnedFilter$ = this.setPinnedFilterSource.asObservable();

  constructor() { }

  public deleteFilter(filterName: string): void {
      this.deleteFilterSource.next(filterName);
  }

  public favoriteFilter(filterView: FilterView): void {
      this.favoriteFilterSource.next(filterView);
  }

  public loadFilter(filterView: FilterView): void {
      this.loadFilterSource.next(filterView);
  }

  public saveFilter(filterName: string, dateOptionSwitch: boolean): void {
    const saveRequest: SaveFilterRequest = {
        name: filterName,
        dateOptionSwitch
    };
    this.saveFilterSource.next(saveRequest);
  }

  public setPinnedFilter(filterView: FilterView): void {
      this.setPinnedFilterSource.next(filterView);
  }
}
