import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-heatmap-legends',
    templateUrl: './heatmap-legends.component.html',
    styleUrls: [
        '../../shared/styles/shared-chart.scss',
        './heatmap-legends.component.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class HeatmapLegendsComponent implements OnChanges {
    @Input() selectedMetric: any;
    public metricId = '';

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedMetric && changes.selectedMetric.currentValue) {
            this.metricId = this.selectedMetric.id;
        }
    }

}
