import { Component, OnDestroy, OnInit } from '@angular/core';
import { FilterService } from '../../shared/filter.service';
import {
  PaceVsRemDemModel,
  PaceVsRemDemService
} from './pace-vs-rem-dem.service';
import { NavigateWithFiltersService } from '../../shared/navigate-with-filters.service';
import { SeriesClickEvent } from '@progress/kendo-angular-charts';
import { BaseDashboardComponent } from '../base-dashboard-component';
import {DateUtils} from '../../shared/date-utils';
import {ComponentConfiguration} from '../../core/models/config';

@Component({
  selector: 'app-pace-vs-rem-dem',
  templateUrl: './pace-vs-rem-dem.component.html',
  styleUrls: ['./pace-vs-rem-dem.component.scss']
})
export class PaceVsRemDemComponent extends BaseDashboardComponent
  implements OnInit, OnDestroy {
  chartData: PaceVsRemDemModel[];
  public chartConfig: ComponentConfiguration;
  public chartPopupSettings = {
      popupClass: ['default-chart-tooltip-settings ']
  };

  constructor(
    private filterService: FilterService, private serv: PaceVsRemDemService, private navService: NavigateWithFiltersService) {
      super();
      this.applyFilterSubscription = this.filterService.filterTriggered$.subscribe(this._loadData.bind(this));
      this.chartConfig = this.serv.getComponentConfig();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.applyFilterSubscription.unsubscribe();
  }

  _loadData(searchOptions: any) {
    this.serv.getPaceVsRemainingDemand(searchOptions).subscribe(this._onLoadSuccess.bind(this));
  }

  _onLoadSuccess(resp: PaceVsRemDemModel[]) {
    resp.forEach(val => {
      val.col = val.isFcstOvrd ? this.chartConfig.config.metric.override.style.color :
          this.chartConfig.config.metric.noOverride.style.color;
    });
    this.chartData = resp;
  }

  onSeriesClick(e: SeriesClickEvent) {
    const dataItem = e.dataItem;
    const odParts = dataItem.od.split('-');
    const filters = {
      rsid: dataItem.rsid,
      origin: odParts[0],
      dest: odParts[1],
      date: DateUtils.convertLocalDateStringToDate(dataItem.effDt)
    };
    this.navService.navigateWithFilters('inventory', filters);
  }

  public legendVisible(args: any): any {
      console.log(args);
      return args.createVisual();
  }
}
