import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import * as d3 from 'd3';
import {HeatmapModel} from '../../models/heatmap.model';
import {HeatmapService} from '../../services/heatmap.service';
import ResizeObserver from 'resize-observer-polyfill';
import {
    SharedChart,
    SharedChartMenuAction,
    SharedChartMenuEvent
} from '../../models/shared-chart.model';
import {TubemapService} from '../../services/tubemap.service';
import { DateUtils } from 'src/app/shared/date-utils';

@Component({
    selector: 'app-heatmap',
    templateUrl: './heatmap.component.html',
    styleUrls: [
        '../../shared/styles/shared-chart.scss',
        './heatmap.component.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class HeatmapComponent implements OnChanges, OnDestroy {
    @ViewChild('heatmap', {static: true}) chartContainer: ElementRef;
    @Input() data: HeatmapModel[];
    @Input() selectedMetric: any;
    public height = 0;
    public width = 0;
    public margin = {
        top: 50,
        right: 85,
        bottom: 100,
        left: 100
    };
    public menuItems = [
        {text: 'View Details', action: SharedChartMenuAction.viewDetails},
        {text: 'Override Advance', action: SharedChartMenuAction.overrideAdvanced},
        {text: 'Override Walk-Up', action: SharedChartMenuAction.overrideWalkUp},
        {text: 'Toggle Auto Pilot', action: SharedChartMenuAction.toggleAutoPilot}
    ];
    public ro: ResizeObserver;
    public heightPct = 92;
    public selectedTubeRecord: HeatmapModel;

    private chartWidth = 0;
    private maxRSIDs = 40;
    private maxDates = 10;
    private rsidKeys = [];
    private deptDtKeys = [];
    private axisScales = {
        train: null,
        deptDt: null
    };
    private axisArea = {
        train: null,
        deptDt: null
    };
    private svg = null;
    private displayedData: HeatmapModel[] = [];
    private scroller = {
        vertical: {
            rect: null,
            displayed: null
        },
        horizontal: {
            rect: null,
            displayed: null
        }
    };
    private selectedRecord: HeatmapModel;

    constructor(private heatmapSvc: HeatmapService, private tubemapSvc: TubemapService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            // Data changes need to have chart rebuilt
            this.removeChartElement();
            this.deptDtKeys = d3.map(this.data, (d) => d.dateLabel).keys().sort((n1, n2) => {
                const d1 = DateUtils.convertLocalDateStringToDate(n1);
                const d2 = DateUtils.convertLocalDateStringToDate(n2);
                if (d1 > d2) {
                    return 1;
                }

                if (d1 < d2) {
                    return -1;
                }

                return 0;
            });

            this.rsidKeys = d3.map(this.data, (d) => d.rsid).keys();
            this.createChart();
        } else if (changes.selectedMetric) {
            // only need to redraw data content
            this.updateChartDataContent();
            this.tubemapSvc.changeMetric(changes.selectedMetric.currentValue.id);
        }

        if (!this.ro) {
            this.ro = new ResizeObserver((entries, observer) => {
                for (const entry of entries) {
                    // This will cause us to lose the scrolling position, will need to address in future
                    this.removeChartElement();
                    this.createChart();
                }
            });
            this.ro.observe(this.chartContainer.nativeElement.offsetParent);
        }
    }

    ngOnDestroy(): void {
        this.ro.unobserve(this.chartContainer.nativeElement.offsetParent);
    }

    public menuOpen(event: any): void {
        this.svg.select('.chart-content').selectAll('.data-rect').classed('heat-selected', false);
        const selectedNode = d3.select(event.target).classed('heat-selected', true).node();
        this.selectedRecord = selectedNode.__data__;
    }

    public processMenuItemClick(event: any): void {
        const menuClickEvent = new SharedChartMenuEvent();
        menuClickEvent.action = event.item.action;
        if (menuClickEvent.action === SharedChartMenuAction.viewDetails) {
            this.selectedTubeRecord = Object.assign({}, this.selectedRecord);
            this.tubemapSvc.displayTubemap(this.selectedTubeRecord, this.selectedMetric.id);
        }

        if (menuClickEvent.action === SharedChartMenuAction.toggleAutoPilot) {
            this.selectedRecord.autoPilotDisabled = !this.selectedRecord.autoPilotDisabled;
            this.updateChartDataContent();
        }

        menuClickEvent.data = this.selectedRecord;
        this.heatmapSvc.menuClick(menuClickEvent);
    }

    private addHorizontalScrollBar(): void {
        const scrollBarWidth = this.axisScales.train.range()[1];
        const trainScroll = d3.scaleBand()
            .range([0, scrollBarWidth])
            .domain(this.rsidKeys);
        const trainScrollBar = this.svg.append('g')
            .attr('class', 'train-scroll-bar')
            .selectAll('.scroll-bar-item')
            .data(this.rsidKeys);
        trainScrollBar.enter()
            .append('rect')
            .attr('data-key', (d) => d)
            .attr('class', 'scroll-bar-item')
            .attr('height', '20px')
            .attr('width', (s) => trainScroll.range()[1] / this.rsidKeys.length)
            .attr('x', (d) => trainScroll(d))
            .attr('y', this.axisScales.deptDt.range()[0]);

        // scroll rect
        const scrollerWidth = (this.maxRSIDs * scrollBarWidth) / this.rsidKeys.length;
        this.scroller.horizontal.rect = this.svg.append('rect')
            .attr('class', 'scroller')
            .attr('x', 0)
            .attr('y', this.axisScales.deptDt.range()[0])
            .attr('height', 20)
            .attr('width', Math.round(scrollerWidth))
            .attr('pointer-events', 'all')
            .attr('cursor', 'ew-resize');
        this.scroller.horizontal.displayed = d3.scaleQuantize()
            .domain([0, scrollBarWidth])
            .range(d3.range(this.rsidKeys.length));
        this.scroller.horizontal.rect.call(d3.drag().on('drag', this.horizontalDragScroll.bind(this)));
    }

    private addVerticalScrollBar(): void {
        const scrollBarHeight = this.axisScales.deptDt.range()[0];
        const dateScroll = d3.scaleBand()
            .range([scrollBarHeight, 0])
            .domain(this.deptDtKeys.reverse());

        const deptDtScrollBar = this.svg.append('g')
            .attr('class', 'date-scroll-bar')
            .selectAll('.scroll-bar-item')
            .data(this.deptDtKeys.reverse());
        deptDtScrollBar.enter()
            .append('rect')
            .attr('data-key', (d) => d)
            .attr('class', 'scroll-bar-item')
            .attr('height', (d) => dateScroll.range()[0] / this.deptDtKeys.length)
            .attr('width', '20px')
            .attr('x', this.axisScales.train.range()[1])
            .attr('y', (d) => dateScroll(d));

        // scroll rect
        const scrollerHeight = (this.maxDates * scrollBarHeight) / this.deptDtKeys.length;
        this.scroller.vertical.rect = this.svg.append('rect')
            .attr('class', 'scroller')
            .attr('x', this.axisScales.train.range()[1])
            .attr('y', 0)
            .attr('height', Math.round(scrollerHeight))
            .attr('width', 20)
            .attr('pointer-events', 'all')
            .attr('cursor', 'ns-resize');
        this.scroller.vertical.displayed = d3.scaleQuantize()
            .domain([0, scrollBarHeight])
            .range(d3.range(this.deptDtKeys.length));
        this.scroller.vertical.rect.call(d3.drag().on('drag', this.verticalDragScroll.bind(this)));
    }

    private createChart(): void {
        const element = this.chartContainer.nativeElement;
        this.width = element.clientWidth;
        this.height = element.clientHeight - 15;

        this.chartWidth = this.width - this.margin.left + this.margin.right;
        if (this.chartWidth < 0 || this.height < 0) {
            return;
        }
        this.svg = d3.select(element).append('svg')
            .attr('width', this.chartWidth)
            .attr('height', this.height)
            .append('g')
            .attr('transform', `translate(${this.margin.left - 25}, ${this.margin.top})`);

        const deptDtHeight = this.height - this.margin.top - (this.rsidKeys.length > this.maxRSIDs ? 20 : 0);
        this.axisScales.deptDt = d3.scaleBand()
            .range([deptDtHeight, 0])
            .domain(this.deptDtKeys.slice(0, this.maxDates).reverse());


        const trainWidth = this.chartWidth - this.margin.left + 25 - (this.deptDtKeys.length > this.maxDates ? 20 : 0);
        this.axisScales.train = d3.scaleBand()
            .range([0, trainWidth])
            .domain(this.rsidKeys.slice(0, this.maxRSIDs));

        this.axisArea.deptDt = d3.axisLeft(this.axisScales.deptDt);
        this.axisArea.train = d3.axisTop(this.axisScales.train);

        this.svg.append('defs')
            .append('pattern')
            .attr('id', 'auto-pilot-pattern')
            .attr('patternUnits', 'userSpaceOnUse')
            .attr('patternTransform', 'rotate(45)')
            .attr('width', 4)
            .attr('height', 4)
            .append('rect')
            .attr('width', '.5')
            .attr('height', '10')
            .attr('transform', 'translate(0,0)')
            .attr('fill', '#000');

        this.svg.append('g').attr('class', 'date-axis');
        this.updateDateAxis();

        this.svg.append('g').attr('class', 'train-axis');
        this.updateTrainAxis();

        this.svg.append('g').attr('class', 'chart-content');
        if (this.deptDtKeys.length > this.maxDates || this.rsidKeys.length > this.maxRSIDs) {
            this.svg.select('.chart-content').on('mousewheel.zoom', f => this.mouseScrollHandler(d3.event));
        }

        if (this.deptDtKeys.length > this.maxDates) {
            this.addVerticalScrollBar();
        }

        if (this.rsidKeys.length > this.maxRSIDs) {
            this.addHorizontalScrollBar();
        }

        this.updateChartDataContent();
    }

    private createChartContentItems(contentContainer: any, isAutoPilot: boolean): void {
        contentContainer.append('rect')
            .attr('class', (d) => {
                if (isAutoPilot) {
                    return 'data-rect auto-pilot';
                } else {
                    return `data-rect heat-map-item ${SharedChart.getMetricColorClass(d[this.selectedMetric.id],
                        this.selectedMetric.id)}-color`;
                }
            })
            .attr('x', (d) => this.axisScales.train(d.rsid))
            .attr('y', (d) => this.axisScales.deptDt(d.dateLabel))
            .attr('rx', 2)
            .attr('width', this.axisScales.train.bandwidth() - 2)
            .attr('height', this.axisScales.deptDt.bandwidth() - 2)
            .attr('data-service-id', (d) => d.rsid)
            .attr('data-service-info', (d) => `${d.trainDepartureTime} ${d.trainOrigin}-${d.trainDestination}`)
            .classed('heat-selected', (d) => this.selectedRecord ? this.selectedRecord.rsid === d.rsid
                && this.selectedRecord.effDepartureDate === d.effDepartureDate : false);
    }

    private horizontalDragScroll(): void {
        const rect = this.scroller.horizontal.rect._groups[0][0];
        const rectX = parseInt(d3.select(rect).attr('x'), 0);
        const nextX = rectX + d3.event.dx;
        const curWidth = parseInt(d3.select(rect).attr('width'), 0);

        const scrollerWidth = this.axisScales.train.range()[1];

        if (nextX < 0 || nextX + curWidth - 1 > scrollerWidth) {
            return;
        }

        d3.select(rect).attr('x', nextX);

        const curIdx = this.scroller.horizontal.displayed(rectX);
        const nextIdx = this.scroller.horizontal.displayed(nextX);

        if (curIdx === nextIdx) {
            return;
        }

        this.horizontalScroll(nextIdx);
    }

    private horizontalMouseScroll(event: any): void {
        const rect = this.scroller.horizontal.rect._groups[0][0];
        const rectX = parseInt(d3.select(rect).attr('x'), 0);

        const scrollerWidth = this.axisScales.train.range()[1];
        const eventX = Math.ceil(scrollerWidth / this.rsidKeys.length);

        let nextX = rectX + (eventX * (event.deltaY < 0 ? -1 : 1));
        const curWidth = parseInt(d3.select(rect).attr('width'), 0);

        if (nextX < 0 || nextX + curWidth - 1 > scrollerWidth) {
            nextX = (nextX < 0) ? 0 : scrollerWidth - curWidth + 1;
        }

        d3.select(rect).attr('x', nextX);

        const curIdx = this.scroller.horizontal.displayed(rectX);
        const nextIdx = curIdx + (event.deltaY > 0 ? 1 : -1);

        if (nextIdx < 0 || nextIdx + this.maxRSIDs > this.rsidKeys.length) {
            return;
        }

        this.horizontalScroll(nextIdx);
    }

    private horizontalScroll(nextIdx: number): void {
        const newData = this.rsidKeys.slice(nextIdx, nextIdx + this.maxRSIDs);
        this.axisScales.train.domain(newData);
        this.updateTrainAxis();
        this.updateChartDataContent();
    }

    private mouseScrollHandler(event: any): void {
        if (event.shiftKey && this.rsidKeys.length > this.maxRSIDs) {
            this.horizontalMouseScroll(event);
        } else if (this.deptDtKeys.length > this.maxDates) {
            this.verticalMouseScroll(event);
        }
    }

    private removeChartElement(): void {
        d3.select(this.chartContainer.nativeElement).select('svg').remove();
    }

    private updateChartDataContent(): void {
        this.displayedData = this.data.filter(d => this.axisScales.train.domain().includes(d.rsid) &&
            this.axisScales.deptDt.domain().includes(d.dateLabel));
        this.svg.select('.chart-content').selectAll('.heat-map-item').remove();
        const cells = this.svg.select('.chart-content')
            .selectAll()
            .data(this.displayedData.filter(d => !d.autoPilotDisabled)).enter();
        this.createChartContentItems(cells, false);
        cells.exit().remove();

        const autoPilotRecords = this.displayedData.filter(d => d.autoPilotDisabled);
        if (autoPilotRecords.length > 0) {
            const autoPilot = this.svg.select('.chart-content')
                .selectAll()
                .data(this.displayedData.filter(d => d.autoPilotDisabled))
                .enter()
                .append('g')
                .attr('class', 'auto-pilot-parent heat-map-item');
            this.createChartContentItems(autoPilot, false);
            // Needed to stack heatmap items to get auto-pilot styling
            this.createChartContentItems(autoPilot, true);
            autoPilot.exit().remove();
        }

    }

    private updateDateAxis(): void {
        this.svg.select('.date-axis')
            .call(this.axisArea.deptDt);
    }

    private updateTrainAxis(): void {
        this.svg.select('.train-axis')
            .call(this.axisArea.train)
            .selectAll('text')
            .attr('y', -5)
            .attr('x', 5)
            .attr('dy', '0')
            .style('transform', 'rotate(-45deg)')
            .style('text-anchor', 'start');
    }

    private verticalDragScroll(): void {
        const rect = this.scroller.vertical.rect._groups[0][0];
        const rectY = parseInt(d3.select(rect).attr('y'), 0);

        const nextY = rectY + d3.event.dy;
        const curHeight = parseInt(d3.select(rect).attr('height'), 0);

        const scrollerHeight = this.axisScales.deptDt.range()[0];
        if (nextY < 0 || nextY + curHeight - 1 > scrollerHeight) {
            return;
        }

        d3.select(rect).attr('y', nextY);

        const curIdx = this.scroller.vertical.displayed(rectY);
        const nextIdx = this.scroller.vertical.displayed(nextY);

        if (curIdx === nextIdx) {
            return;
        }

        this.verticalScroll(nextIdx);
    }

    private verticalMouseScroll(event: any): void {
        const rect = this.scroller.vertical.rect._groups[0][0];
        const rectY = parseInt(d3.select(rect).attr('y'), 0);

        const scrollerHeight = this.axisScales.deptDt.range()[0];
        const eventY = Math.ceil(scrollerHeight / this.deptDtKeys.length);

        let nextY = rectY + (eventY * (event.deltaY < 0 ? -1 : 1));
        const curHeight = parseInt(d3.select(rect).attr('height'), 0);

        if (nextY < 0 || nextY + curHeight - 1 > scrollerHeight) {
            nextY = (nextY < 0) ? 0 : scrollerHeight - curHeight + 1;
        }

        d3.select(rect).attr('y', nextY);

        const curIdx = this.scroller.vertical.displayed(rectY);
        const nextIdx = curIdx + (event.deltaY > 0 ? 1 : -1);

        if (nextIdx < 0 || nextIdx + this.maxDates > this.deptDtKeys.length) {
            return;
        }
        event.preventDefault();
        this.verticalScroll(nextIdx);
    }

    private verticalScroll(nextIdx: number): void {
        const newData = this.deptDtKeys.slice(nextIdx, nextIdx + this.maxDates).reverse();
        this.axisScales.deptDt.domain(newData);
        this.updateDateAxis();
        this.updateChartDataContent();
    }
}
