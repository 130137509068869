<kendo-chart class="overrideAlertsChart" [style.height.px]="rowHt - 50"
             (seriesClick)="onSeriesClick($event)">
  <kendo-chart-legend [visible]="false"></kendo-chart-legend>
  <kendo-chart-value-axis>
    <kendo-chart-value-axis-item [title]="{ text: 'Difference from Forecast' }">
      <kendo-chart-value-axis-item-labels format="p">
      </kendo-chart-value-axis-item-labels>
    </kendo-chart-value-axis-item>
  </kendo-chart-value-axis>
  <kendo-chart-category-axis>
    <kendo-chart-category-axis-item [majorGridLines]="{ visible: false }">
      <kendo-chart-category-axis-item-labels
        [visible]="false"
      ></kendo-chart-category-axis-item-labels>
    </kendo-chart-category-axis-item>
  </kendo-chart-category-axis>
  <kendo-chart-series>
    <kendo-chart-series-item type="column"
      [data]="chartData"
      [name]="chartConfig.config.metric.overrides.name"
      [color]="chartConfig.config.metric.overrides.style.color"
      [categoryField]="'key'"
      [field]="'diffFrmFcst'"
    >
      <kendo-chart-series-item-tooltip [background]="'#FFFFFF'">
        <ng-template let-value="dataItem">
            <div class="tooltip-container">
                <div class="tooltip-row">
                    <div class="tooltip-label">Service:</div>
                    <div class="tooltip-value">{{value.rsid}}</div>
                </div>
                <div class="tooltip-row">
                    <div class="tooltip-label">Journey:</div>
                    <div class="tooltip-value">{{value.od}}</div>
                </div>
                <div class="tooltip-row">
                    <div class="tooltip-label">Departure Date:</div>
                    <div class="tooltip-value">{{value.effDt}}</div>
                </div>
                <div class="tooltip-row">
                    <div class="tooltip-label">Diff From Fcst:</div>
                    <div class="tooltip-value">{{value.diffFrmFcst | percent:'0.1-1'}}</div>
                </div>
            </div>
        </ng-template>
      </kendo-chart-series-item-tooltip>
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>
