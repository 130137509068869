import {Component, EventEmitter, Output, OnInit} from '@angular/core';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
    @Output() sideNavAction = new EventEmitter();
    public groupSalesKeyName = 'hasGroupSalesAccess';
    public showGroupSales = false;

    constructor() {
    }

    ngOnInit() {
        this.getGroupSalesAccess();
    }

    public toggleSideNav(): void {
        this.sideNavAction.emit();
    }

    public getGroupSalesAccess(): boolean {
        const value = JSON.parse(localStorage.getItem('userConfig'));
        this.showGroupSales = (value[this.groupSalesKeyName] === false);
        return this.showGroupSales;
    }

}
