<kendo-dialog-titlebar>
    Bulk Override
</kendo-dialog-titlebar>
<div *ngIf="!loading" class="dialog-content-container">
    <div [style.text-align]="'center'" [style.padding-bottom.px]="5">
        <label [style.margin-right.px]="15">Bucket</label>
        <kendo-switch [onLabel]="' '" [offLabel]="' '" [checked]="bulkToggle"
                      [(ngModel)]="bulkToggle"></kendo-switch>
        <label [style.margin-left.px]="15">Profile</label>
    </div>
    <div [hidden]="bulkToggle">
        <app-bulk-bucket-grid [buckets]="invOverride.buckets"></app-bulk-bucket-grid>
    </div>
    <div [hidden]="!bulkToggle">
        <app-profile-selector [profiles]="invOverride.profiles"></app-profile-selector>
    </div>
</div>
<div *ngIf="loading" class="loading-overlay">
    <div class="loading-background"></div>
    <div class="loading-icon">
        <i class="fas fa-2x fa-spinner fa-spin"></i>
    </div>
</div>
<kendo-dialog-actions>
    <button class="k-button ra-dialog-button" (click)="closeDialog()">Cancel</button>
    <button class="k-button ra-dialog-button" (click)="onSave()" [disabled]="!isValid()">
        Override
    </button>
    <button class="k-button ra-dialog-button" (click)="onApprove()">Approve</button>
</kendo-dialog-actions>