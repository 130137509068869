<div class="spinner-wrapper" *ngIf="checkHiddenFilter('allFilters')">
    <div class="sidebar-sticky">
        <div class="nav-container">
            <app-filter-toolbar [hidden]="isToolbarHidden()"
                                [buildViewFilter]="buildViewFilter.bind(this)"
                                [isFilterViewValid]="canApplyFilters()"></app-filter-toolbar>
            <div class="filter-container">
                <h5>View</h5>
                <div>
                    <kendo-dropdownlist *ngIf="checkHiddenFilter('viewTypeData')" [data]="viewTypeData.allValues"
                                        [(value)]="viewTypeData.selectedValues"
                                        [textField]="'name'"
                                        [popupSettings]="popupSettings"
                                        [valueField]="'id'" class="filter-drop-down"></kendo-dropdownlist>
                    <kendo-multiselect *ngIf="checkHiddenFilter('statusTypeData')"
                                       [data]="statusTypeData.allValues"
                                       [popupSettings]="popupSettings"
                                       [(value)]="statusTypeData.selectedValues"
                                       [autoClose]="false"
                                       [textField]="'name'"
                                       [valueField]="'id'"
                                       class="filter-drop-down"></kendo-multiselect>
                    <div class="filter-item">
                        <h6 class="filter-name">Class *</h6>
                        <kendo-dropdownlist [data]="classData.allValues"
                                        [(value)]="classData.selectedValues"
                                        [textField]="'name'"
                                        [valueField]="'id'"
                                        [popupSettings]="popupSettings"
                                        class="filter-drop-down"></kendo-dropdownlist>
                    </div>
                </div>
                <div class="date-switch-container">
                    <label [style.padding-left.px]="0">Date Picker *</label>
                </div>
                <div class="filter-item" *ngIf="dateSwitch">
                    <div class="date-picker-range">
                        <kendo-datepicker [(value)]="startDate" class="filter-date"
                                          [popupSettings]="popupSettings"
                                          (valueChange)="onStartDateChange($event)"></kendo-datepicker>
                        <kendo-numerictextbox [(value)]="startDateRange"
                                              [format]="'n0'" (valueChange)="onStartDateRangeChange($event)"
                                              [decimals]="0" class="filter-input"></kendo-numerictextbox>
                    </div>
                    <div class="date-picker-range">
                        <kendo-datepicker [(value)]="endDate" class="filter-date"
                                          [popupSettings]="popupSettings"
                                          (valueChange)="onEndDateChange($event)"></kendo-datepicker>
                        <kendo-numerictextbox [(value)]="endDateRange"
                                              [format]="'n0'" (valueChange)="onEndDateRangeChange($event)"
                                              [decimals]="0" class="filter-input">
                        </kendo-numerictextbox>
                    </div>
                </div>
                <div class="filter-item" *ngIf="!dateSwitch">
                    <kendo-dropdownlist [data]="startPeriod.filteredValues"
                                        [(value)]="startPeriod.selectedValues"
                                        [popupSettings]="popupSettings"
                                        [filterable]="true"
                                        (filterChange)="handleFilterStart($event)"
                                        [textField]="'name'"
                                        [valueField]="'id'"
                                        (valueChange)="startPeriodChange($event)"
                                        class="filter-drop-down"></kendo-dropdownlist>
                    <kendo-dropdownlist [data]="endPeriod.filteredValues"
                                        [(value)]="endPeriod.selectedValues"
                                        [popupSettings]="popupSettings"
                                        [filterable]="true"
                                        (filterChange)="handleFilterEnd($event)"
                                        [textField]="'name'"
                                        [valueField]="'id'" class="filter-drop-down"></kendo-dropdownlist>
                </div>
            </div>
            <div class="filter-container">
                <h5>Filters</h5>
                <div class="filter-item" *ngIf="checkHiddenFilter('portfolioData')">
                    <h6 class="filter-name">Market *</h6>
                    <kendo-multiselect [data]="portfolioData.allValues"
                                       [autoClose]="false"
                                       [textField]="'name'"
                                       [valueField]="'id'"
                                       [popupSettings]="popupSettings"
                                       [tagMapper]="dropdownTagMapper"
                                       class="filter-drop-down"
                                       [(value)]="portfolioData.selectedValues"></kendo-multiselect>
                </div>
                <div class="filter-item">
                    <h6 class="filter-name">Journey {{ filterLayout?.journeyFilterConfig.isRequired ? '*' : ''}}</h6>
                    <app-filter-od-drop-downs #odSelector [journeyFilterConfig]="filterLayout?.journeyFilterConfig"></app-filter-od-drop-downs>
                </div>
                <div class="filter-item" *ngIf="checkHiddenFilter('timeData')">
                    <h6 class="filter-name">Time Picker *</h6>
                    <app-filter-time-picker [filterValue]="filterTimePickerValue"></app-filter-time-picker>
                </div>
                <div class="filter-item" *ngIf="checkHiddenFilter('groupSize')">
                    <h6 class="filter-name">Group Size *</h6>
                    <kendo-numerictextbox [style.width.%]="100"
                                          [(value)]="groupSize"
                                          [format]="'n0'"
                                          [decimals]="0" class="filter-input"></kendo-numerictextbox>
                </div>
                <div class="filter-item">
                    <h6 class="filter-name">DOW</h6>
                    <kendo-multiselect [data]="dayOfWeekData.allValues"
                                       [(value)]="dayOfWeekData.selectedValues"
                                       [autoClose]="false"
                                       [textField]="'name'"
                                       [popupSettings]="popupSettings"
                                       [tagMapper]="dropdownTagMapper"
                                       [valueField]="'id'" class="filter-drop-down"></kendo-multiselect>
                </div>
                <div class="filter-item" *ngIf="checkHiddenFilter('journeyTypeData')">
                    <h6 class="filter-name">Journey Type</h6>
                    <kendo-multiselect [data]="journeyTypeData.allValues"
                                       [(value)]="journeyTypeData.selectedValues"
                                       [autoClose]="false"
                                       [textField]="'name'"
                                       [valueField]="'id'"
                                       [popupSettings]="popupSettings"
                                       [tagMapper]="dropdownTagMapper"
                                       class="filter-drop-down"></kendo-multiselect>
                </div>
                <div class="filter-item" *ngIf="checkHiddenFilter('daypartData')">
                    <h6 class="filter-name">Daypart</h6>
                    <kendo-multiselect [data]="daypartData.allValues"
                                       [(value)]="daypartData.selectedValues"
                                       [autoClose]="false"
                                       [textField]="'name'"
                                       [valueField]="'id'"
                                       [popupSettings]="popupSettings"
                                       [tagMapper]="dropdownTagMapper"
                                       class="filter-drop-down"></kendo-multiselect>
                </div>
                <div class="filter-item" *ngIf="checkHiddenFilter('rsidData')">
                    <h6 class="filter-name">Service</h6>
                    <kendo-multiselect [data]="rsidData.allValues"
                                       [(value)]="rsidData.selectedValues"
                                       [autoClose]="false"
                                       [textField]="'name'"
                                       [popupSettings]="popupSettings"
                                       [tagMapper]="dropdownTagMapper"
                                       [valueField]="'id'"
                                       [allowCustom]="true"
                                       [valueNormalizer]="rsidValueNormalizer"
                                       (valueChange)="rsidMultiSelectChange()"
                                       #rsidMultiSelect
                                       class="filter-drop-down"></kendo-multiselect>
                </div>
            </div>
            <div class="filter-container">
                <span>
                    <button kendoButton [disabled]="!canApplyFilters()" (click)="onSearchClicked()">Search</button>
                </span>
                <span class="clear-filter-btn">
                    <button kendoButton [disabled]="!canClearFilters()" (click)="onClearClicked()">Clear</button>
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="this.filtersLoading" class="k-i-loading"></div>
</div>