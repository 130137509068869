export const environment = {
    apiBaseUrl: 'https://api.demo.farevantage.io/rail/v1',
    auth: {
        algorithm: 'RSA256',
        audience: 'https://ra-rail-dev.revenueanalytics.io',
        clientID: 'ror4EhNAq0PqXoA3rORlgFttYwtPcny0',
        domain: 'ra-rail-dev.auth0.com',
        responseType: 'token id_token',
        scope: 'openid profile email'
    },
    production: true
};
