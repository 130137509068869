import * as moment from 'moment';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

export class DateUtils {

    public static addDays(dt: Date, numDays: number) {
        return new Date(dt.getFullYear(), dt.getMonth(), (dt.getDate() + numDays));
    }

    public static daysDiff(a: Date, b: Date) {
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / MS_PER_DAY);
    }

    public static convertDateToStringFormat(dateValue: Date, format: string = 'YYYY-MM-DD'): string {
        return moment(dateValue).format(format);
    }

    public static convertToLocaleDateString(dateString: string, format: string = 'YYYY-MM-DD'): string {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return this.convertToDate(dateString, format).toLocaleDateString(undefined, options);
    }

    public static convertDateTimeToLocaleString(dateValue: Date, format: string = 'YYYY-MM-DD HH:mm'): string {
        const dateString = moment(dateValue).format(format);
        const dateDate = this.convertToDate(dateString, format);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        return dateDate.toLocaleDateString(undefined, options);
    }

    public static convertLocalDateString(dateString: string): any {
        moment.locale(this.getUsersLocale());
        const format = moment.localeData().longDateFormat('L');
        return moment(dateString, format);
    }

    public static convertLocalDateStringToDate(dateString: string): Date {
        return this.convertLocalDateString(dateString).toDate();
    }

    public static convertToDate(dateString: string, format: string = 'YYYY-MM-DD'): Date {
        return moment(dateString, format).toDate();
    }

    public static getUsersLocale(defaultValue: string = 'en-GB'): string {
        if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return defaultValue;
        }

        const win = window.navigator as any;
        let lang = win.languages ? win.languages[0] : defaultValue;
        lang = lang || win.language || win.browserLanguage || win.userLanguage;
        return lang;
    }
}
