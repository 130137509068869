import {Component, Input, OnInit} from '@angular/core';
import {FilterService} from '../shared/filter.service';
import {FilterToolbarService} from './shared/filter-toolbar.service';
import {FilterView, SaveFilterRequest, ViewConfigurationItem} from './shared/filter-view.model';

@Component({
    selector: 'app-filter-toolbar',
    templateUrl: './filter-toolbar.component.html',
    styleUrls: ['./filter-toolbar.component.scss']
})
export class FilterToolbarComponent implements OnInit {
    @Input() buildViewFilter: any;
    @Input() isFilterViewValid: boolean;
    public filterData: any;

    constructor(private filterService: FilterService, private filterToolbarService: FilterToolbarService) {
        this.loadAllFilters();
        this.filterToolbarService.saveFilter$.subscribe(saveFilterRequest => {
            this.saveFilter(saveFilterRequest);
        });
        this.filterToolbarService.loadFilter$.subscribe(filterView => {
            this.loadViewFilter(filterView);
        });
        this.filterToolbarService.favoriteFilter$.subscribe(filterView => {
            this.favoriteFilter(filterView);
        });
        this.filterToolbarService.deleteFilter$.subscribe(filterName => {
            this.deleteViewFilter(filterName);
        });
    }

    ngOnInit() { }

    public deleteViewFilter(filterName: string): void {
        this.filterService.deleteFilterView(filterName).subscribe(() => {
            this.loadAllFilters();
        }, error => {
            console.error(`Error during delete filter: ${error}`);
        });
    }

    public favoriteFilter(filterView: FilterView): void {
        this.filterService.favoriteFilterView(filterView.name, filterView.isFavorite).subscribe(() => {
            return;
        }, error => {
            console.error(`Error during favorite filter request: ${error}`);
        });
    }

    public onPinnedClick(isPinned: boolean): void {
        let pinnedFilter: FilterView = null;
        if (isPinned) {
            pinnedFilter = this.buildViewFilter();
            pinnedFilter.name = 'Pinned Filter';
        }

        this.filterToolbarService.setPinnedFilter(pinnedFilter);
    }

    private addDateOptionSwitch(filterView: FilterView, dateOptionSwitch: boolean): void {
        const dateOptionConfig: ViewConfigurationItem = {
            id: 'dateOptionSwitch',
            name: 'Date Option Switch',
            values: dateOptionSwitch
        };
        filterView.viewConfiguration.push(dateOptionConfig);
    }

    private loadViewFilter(filterView: FilterView): void {
        this.filterService.loadFilterView(filterView);
    }

    private loadAllFilters(): void {
        this.filterService.loadAllFilterViews().subscribe(results => {
            this.filterData = results;
        }, error => {
            console.error(`Error during loading of filters: ${error}`);
        });
    }

    private saveFilter(saveRequest: SaveFilterRequest): void {
        const viewFilter = this.buildViewFilter();
        viewFilter.name = saveRequest.name;
        this.addDateOptionSwitch(viewFilter, saveRequest.dateOptionSwitch);
        this.filterService.saveFilterView(viewFilter).subscribe(() => {
            this.loadAllFilters();
        }, error => {
            console.error(`Error occurred while saving filter: ${error}`);
        });
    }

}
