import {Component, Input, OnInit} from '@angular/core';
import {FilterView} from '../shared/filter-view.model';
import {FilterToolbarService} from '../shared/filter-toolbar.service';
import {SearchUtils} from '../../shared/helpers/search-utils';

@Component({
  selector: 'app-favorite-filter-selector',
  templateUrl: './favorite-filter-selector.component.html',
  styleUrls: ['./favorite-filter-selector.component.scss']
})
export class FavoriteFilterSelectorComponent implements OnInit {
     private static staticObject: FavoriteFilterSelectorComponent;
  @Input() public set filters(pFilterViews: FilterView[]) {
    this.setFavoriteFilters(pFilterViews);
    this.updateFilterViewList();
  }

  public filterViews: FilterView[] = [];
  public selectedFilter: FilterView = null;
  private favoriteFilters: FilterView[] = null;
  private pinnedFilter: FilterView = null;

  constructor(private filterToolbarService: FilterToolbarService) {
      FavoriteFilterSelectorComponent.staticObject = this;
      this.filterToolbarService.setPinnedFilter$.subscribe(filterView => {
          this.updatePinnedFilter(filterView);
      });
      this.filterToolbarService.loadFilter$.subscribe(filterView => {
          this.selectedFilter = filterView;
      });
      this.filterToolbarService.favoriteFilter$.subscribe(filterView => {
          this.toggleFavoriteFilter(filterView);
      });
  }

  ngOnInit() { }

  public onButtonClick(): void {
    if (this.selectedFilter === null) {
      return;
    }
    this.filterToolbarService.loadFilter(this.selectedFilter);
  }

  public onItemClick(item: FilterView): void {
    this.selectedFilter = item;
    this.onButtonClick();
  }

  private addFavoriteFilter(filterView: FilterView): void {
      const filterItem = this.favoriteFilters.find(fv => fv.name === filterView.name);
      if (filterItem) {
          return;
      }
      const compareFilters = (fv1: FilterView, fv2: FilterView) => {
          return fv1.name.toLowerCase() > fv2.name.toLowerCase() ? 1 : -1;
      };
      const insertIndex = SearchUtils.binarySearch(this.favoriteFilters, filterView, compareFilters);
      this.favoriteFilters.splice(insertIndex, 0, filterView);
  }

  private addPinnedFilter(filterView: FilterView): void {
    this.selectedFilter = filterView;
    this.pinnedFilter = filterView;
    this.filterViews.unshift(this.pinnedFilter);
  }

  private removeFavoriteFilter(filterView: FilterView): void {
      const filterIndex = this.favoriteFilters.findIndex(fv => fv.name === filterView.name);
      if (filterIndex >= 0) {
          this.favoriteFilters.splice(filterIndex, 1);
      }
  }

  private removePinnedFilter(): void {
    if (this.pinnedFilter === null) {
      return;
    }
    if (this.selectedFilter.name === 'Pinned Filter') {
      this.selectedFilter = null;
    }
    const pos = this.filterViews.indexOf(this.pinnedFilter);
    this.filterViews.splice(pos, 1);
  }

  private setFavoriteFilters(filters: FilterView[]): void {
      if (filters === undefined) {
          return;
      }
      this.favoriteFilters = filters.filter(f => f.isFavorite);
  }

  private toggleFavoriteFilter(filterView: FilterView): void {
      if (filterView.isFavorite) {
          this.addFavoriteFilter(filterView);
      } else {
          this.removeFavoriteFilter(filterView);
      }
      this.updateFilterViewList();
  }

  private updateFilterViewList(): void {
    this.filterViews = [];
    if (this.pinnedFilter !== null) {
      this.filterViews.push(this.pinnedFilter);
    }
    if (this.favoriteFilters) {
        this.filterViews.push(...this.favoriteFilters);
    }
  }

  private updatePinnedFilter(filterView: FilterView): void {
      if (filterView) {
          this.addPinnedFilter(filterView);
      } else {
          this.removePinnedFilter();
      }
  }

}
