import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatRadioModule} from '@angular/material/radio';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AuthModule } from './auth/auth.module';
import { HelpComponent } from './help/help.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForecastComponent } from './forecast/forecast.component';
import { SidebarNavComponent } from './sidebar-nav/sidebar-nav.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DatePickerModule, DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { SwitchModule, InputsModule } from '@progress/kendo-angular-inputs';
import { NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ButtonModule, ButtonsModule, ButtonGroupModule } from '@progress/kendo-angular-buttons';
import { DialogModule, DialogsModule } from '@progress/kendo-angular-dialog';
import { ContextMenuModule, MenuModule } from '@progress/kendo-angular-menu';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { PopupModule } from '@progress/kendo-angular-popup';

import 'hammerjs';
import { ForecastOverrideComponent } from './forecast/forecast-override/forecast-override.component';
import { LegendTooltipComponent } from './shared/legend-tooltip/legend-tooltip.component';
import {DashboardModule} from './dashboard/dashboard.module';
import { GaugesModule } from '@progress/kendo-angular-gauges';


import '@progress/kendo-angular-intl/locales/en-GB/all';
import { NavComponent } from './nav/nav.component';
import {AuthGuardService} from './auth/auth-guard.service';
import {TokenInterceptorService} from './auth/token-interceptor.service';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import {SideMenuModule} from './shared/components/side-menu/side-menu.module';
import { ForecastBreadcrumbComponent } from './forecast/forecast-breadcrumb/forecast-breadcrumb.component';
import {FilterToolbarModule} from './filter-toolbar/filter-toolbar.module';
import {NotificationModule} from './shared/components/notification/notification.module';
import {MainGuard} from './core/guards/main/main.guard';
import { AccessDeniedComponent } from './shared/components/access-deined/access-denied.component';
import {UrlStore} from './shared/url-store';
import {AppExcelExportModule} from './shared/excel-export/excel-export.module';
import { TopBarComponent } from './shared/components/top-bar/top-bar.component';
import {IntlService} from '@progress/kendo-angular-intl';
import {FirstDayIntlService} from './shared/services/first-day-intl.service';
import { FilterTimePickerComponent } from './shared/components/filter-time-picker/filter-time-picker.component';
import { FilterODDropDownsComponent } from './shared/components/filter-od-drop-downs/filter-od-drop-downs.component';
import {SettingsModule} from './settings/settings.module';
import { UserMenuComponent } from './nav/user-menu/user-menu.component';
import { ForecastChartComponent } from './forecast/components/forecast-chart/forecast-chart.component';
import {NotificationPopupModule} from './shared/notification-popup/notification-popup.module';


export const AppRoutes: Routes = [
    { path: '',
      canActivate: [MainGuard],
      canLoad: [MainGuard],
      canActivateChild: [AuthGuardService, MainGuard],
      component: HomeComponent,
      children: [
          { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService]  },
          { path: 'forecast', component: ForecastComponent, canActivate: [AuthGuardService]  },
          {
              path: 'inventory',
              loadChildren: () => import('./inventory/inventory.module').then(mod => mod.InventoryModule)
          },
          {
              path: 'business-rules',
              canActivate: [AuthGuardService],
              loadChildren: () => import('./legacy-business-rules/legacy-business-rules.module').then(mod => mod.LegacyBusinessRulesModule)
          },
          {
              path: 'business-rules-v2',
              canActivate: [AuthGuardService],
              loadChildren: () => import('./business-rules/business-rules.module').then(mod => mod.BusinessRulesModule)
          },
          {
              path: 'group-sales',
              canActivate: [AuthGuardService],
              loadChildren: () => import('./group-sales/group-sales.module').then(mod => mod.GroupSalesModule)
          },
          {
              path: 'status',
              canActivate: [AuthGuardService],
              loadChildren: () => import('./status/status.module').then(mod => mod.StatusModule)
          },
          { path: 'help', component: HelpComponent, canActivate: [AuthGuardService]  },
          { path: '', redirectTo: 'dashboard', pathMatch: 'prefix' }
      ]},
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(mod => mod.LoginModule)
    },
    {
        path: UrlStore.ui.accessDeined,
        canActivate: [AuthGuardService],
        canLoad: [AuthGuardService],
        component: AccessDeniedComponent
    }
    ];

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HelpComponent,
        ForecastComponent,
        SidebarNavComponent,
        ForecastOverrideComponent,
        LegendTooltipComponent,
        NavComponent,
        ForecastBreadcrumbComponent,
        AccessDeniedComponent,
        TopBarComponent,
        FilterTimePickerComponent,
        FilterODDropDownsComponent,
        UserMenuComponent,
        ForecastChartComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        MatSidenavModule,
        BrowserAnimationsModule,
        AuthModule,
        DropDownsModule,
        GridModule,
        ChartsModule,
        DatePickerModule,
        SwitchModule,
        NumericTextBoxModule,
        LayoutModule,
        ButtonModule,
        ButtonsModule,
        ButtonGroupModule,
        FormsModule,
        DialogModule,
        MatRadioModule,
        ReactiveFormsModule,
        ContextMenuModule,
        ExcelExportModule,
        PopupModule,
        DashboardModule,
        RouterModule.forRoot(AppRoutes),
        GaugesModule,
        MatTabsModule,
        MatCardModule,
        MatToolbarModule,
        MatMenuModule,
        MatListModule,
        MatIconModule,
        DateInputsModule,
        DialogsModule,
        InputsModule,
        MenuModule,
        TooltipModule,
        SideMenuModule,
        FilterToolbarModule,
        NotificationModule,
        AppExcelExportModule,
        SettingsModule,
        NotificationPopupModule
    ],
    providers: [
        AuthGuardService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        },
        {
            provide: IntlService,
            useClass: FirstDayIntlService
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
