<div>
    <kendo-grid [data]="tableGrid.gridData"
                [sortable]="true"
                [sort]="tableGrid.gridSort"
                (sortChange)="tableGrid.sortChange($event)"
                [selectable]="true"
                [rowHeight]="tableGrid.options.rowHeight"
                [height]="rowHt - 70"
                [scrollable]="'virtual'"
                (selectionChange)="gridUserSelectionChange($event)">
        <kendo-grid-column title="Dep Date" field="dt" format="{0:d}" [width]="80" [headerClass]="'ra-grid-header'"></kendo-grid-column>
        <kendo-grid-column title="Service" field="rsid" [headerClass]="'ra-grid-header'"></kendo-grid-column>
        <kendo-grid-column title="Journey" field="od" [headerClass]="'ra-grid-header'"></kendo-grid-column>
        <kendo-grid-column title="Rev Opp" field="revOpp" format="{0:n0}"
                           [headerClass]="['ra-grid-header', 'col-pull-right']"
                           [class]="'col-pull-right'"></kendo-grid-column>
        <kendo-grid-column title="Rem Dmd" field="remDem" format="{0:n0}"
                           [headerClass]="['ra-grid-header', 'col-pull-right']"
                           [class]="'col-pull-right'"></kendo-grid-column>
        <kendo-grid-column title="Curr Profile" field="curr" [headerClass]="'ra-grid-header'"></kendo-grid-column>
        <kendo-grid-column title="Rec Profile" field="rec" [headerClass]="'ra-grid-header'"></kendo-grid-column>
    </kendo-grid>
</div>