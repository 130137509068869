import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { Auth0userProfile } from 'auth0-js';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import {environment} from 'src/environments/environment';
import {forkJoin, Observable, of, Subscription, timer} from 'rxjs';
import {ConfigService} from '../core/services/config/config.service';
import {UrlStore} from '../shared/url-store';
import {mergeMap} from 'rxjs/operators';

@Injectable()
export class AuthService {
    userProfile: any;
    expiresIn: BehaviorSubject<number> = new BehaviorSubject(null);

    private protocol = window.location.hostname === 'localhost' ? 'http' : 'https';
    private auth0 = new auth0.WebAuth({
        ...environment.auth,
        redirectUri: `${this.protocol}://${window.location.hostname}:${window.location.port}/login/complete`
    });
    private timer = null;
    private refreshSub: Subscription;

    constructor(private router: Router, private configSvc: ConfigService) {
        // ensure the timer runs even if the user has refreshed the page
        this.startTimer();
        this.scheduleRenewal();
    }

    public async getProfile(): Promise<any> {
        if (this.userProfile) {
            return this.userProfile;
        }

        const accessToken: string = this.getAccessToken();
        if (!accessToken) {
            throw new Error('Access Token must exist to fetch profile');
        }

        const self = this;
        return new Promise<any>((res, rej) => {
            this.auth0.client.userInfo(accessToken, (err, profile) => {
                if (err) {
                    rej(err);
                } else {
                    self.userProfile = profile;
                    res(profile);
                }
            });
        });
    }

    public beginLogin(landing: string = UrlStore.ui.landing): void {
        // the landing page must be stored in local storage since we will be redirecting
        // to auth0 for login and variables in memory will be wiped
        localStorage.setItem('landing', landing);
        this.auth0.authorize({prompt: 'login', state: 'state'});
    }

    public async completeLogin() {
        this.auth0.parseHash((err, result) => {
            if (err) {
                console.error(err);
                this.router.navigate([UrlStore.ui.loginError]);
                return;
            }

            this.auth0.client.userInfo(result.accessToken, (e, info) => {
                if (e) {
                    console.error(e);
                } else {
                    this.storeAuth(result, info);
                    forkJoin([this.loadConfig()]).subscribe(([config]) => {
                        this.storeConfig(config);
                        this.storeAppConfig(config.appConfig);
                        const landing = localStorage.getItem('landing') || UrlStore.ui.landing;
                        this.router.navigateByUrl(landing);
                    }, error => {
                        console.error(error);
                        this.router.navigate([UrlStore.ui.loginError]);
                    });
                }
            });
        });
    }

    public extendLogin() {
        const self = this;
        this.auth0.checkSession({}, (err, result) => {
            if (err) {
                console.error('Could not get a new token using silent authentication: \n' + err);
            } else {
                console.log('Extending the user session...');
                this.storeAuth(result);
            }
        });
    }

    public logout() {
        this.unscheduleRenewal();
        localStorage.clear();
        this.auth0.logout({
            returnTo: `${this.protocol}://${window.location.hostname}:${window.location.port}`
        });
    }

    public isConfigured(): boolean {
        return localStorage.getItem('userConfig') !== null;
    }

    public isLoggedIn(): boolean {
        const token = localStorage.getItem('id_token');
        const expires = +localStorage.getItem('expires_at');
        return token && expires > new Date().getTime();
    }

    public getAccessToken(): string {
        return localStorage.getItem('access_token');
    }

    public getConfig(): any {
        const config = JSON.parse(localStorage.getItem('userConfig'));
        if (config) {
            return config;
        }
        this.logout();
    }

    public getIdToken(): string {
        return localStorage.getItem('id_token');
    }

    public getEmail(): string {
        return localStorage.getItem('email');
    }

    public getProfilePic(): string {
        return localStorage.getItem('picture');
    }

    public getexpiresIn(): number {
        const expires = +localStorage.getItem('expires_at');
        return Math.max((expires - new Date().getTime()) / 1000, 0);
    }

    public getUserInitials() {
        return localStorage.getItem('userInitials');
    }

    public getUserName(): string {
        return localStorage.getItem('userName');
    }

    public loadConfig(): Observable<any> {
        return this.configSvc.getConfig();
    }

    public loadAppConfig(): Observable<any> {
        return this.configSvc.getAppConfig();
    }

    public unscheduleRenewal(): void {
        if (!this.refreshSub) {
            return;
        }
        this.refreshSub.unsubscribe();
    }

    private startTimer() {
        // setup a timer to check for expiring tokens and log the user out accordingly
        this.clearTimer();
        let timeLeft = this.getexpiresIn();

        if (!timeLeft) {
            return;
        }

        this.timer = setInterval(() => {
            timeLeft = this.getexpiresIn();
            this.expiresIn.next(timeLeft);
            if (timeLeft <= 0) {
                this.clearTimer();
                this.logout();
            }
        }, 500);
    }

    private clearTimer() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    private scheduleRenewal(): void {
        if (!this.isLoggedIn()) {
            return;
        }

        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));

        const source = of(expiresAt).pipe(
            mergeMap(expires => {
                const now = Date.now();
                const refreshAt = expires - (1000 * 30);
                return timer(Math.max(1, refreshAt - now));
            })
        );

        this.refreshSub = source.subscribe(() => {
            this.extendLogin();
        });
    }

    private storeAuth(authResult, info?: Auth0userProfile) {
        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);
        this.scheduleRenewal();
        if (info) {
            localStorage.setItem('email', info.email);
            localStorage.setItem('picture', info.picture);
            localStorage.setItem('userInitials', info.given_name.charAt(0) + info.family_name.charAt(0));
            localStorage.setItem('userName', info.name);
        }
    }

    private storeAppConfig(config: any): void {
        localStorage.setItem('appConfig', JSON.stringify(config));
    }

    private storeConfig(config: any): void {
        localStorage.setItem('userConfig', JSON.stringify(config));
    }
}
