import {environment as env, environment} from '../../environments/environment';

export class UrlStore {
    public static readonly ui = {
        landing: '/',
        accessDeined: 'accessDeined',
        dashboard: 'dashboard',
        forecast: 'forecast',
        inventory: 'inventory',
        groupSales: 'group-sales',
        businessRules: 'business-rules',
        businessRulesV2: 'business-rules-v2',
        loginError: 'login/error',
        status: 'status'
    };

    public static readonly api = {
        config: `${environment.apiBaseUrl}/config`,
        businessRules: {
            base: `${env.apiBaseUrl}/business_rules`,
            bucketRules: `${env.apiBaseUrl}/business_rules/buckets`,
            bucketValidation: `${env.apiBaseUrl}/business_rules/validate/bucket`,
            daysPrior: `${env.apiBaseUrl}/business_rules/days_prior`,
            exclusionDates: `${env.apiBaseUrl}/business_rules/exclusion_dates`,
            export: `${env.apiBaseUrl}/business_rules/export`,
            journeyRules: `${env.apiBaseUrl}/business_rules/journeys`,
            parentRules: `${env.apiBaseUrl}/business_rules/parent_rules`,
            parentValidation: `${env.apiBaseUrl}/business_rules/validate/rules`,
            ruleTypes: `${env.apiBaseUrl}/business_rules/rule_types`
        },
        comment: `${environment.apiBaseUrl}/comment`,
        dashboard: {
            revenueChart:  `${env.apiBaseUrl}/dashboard/revenue`
        },
        forecast: {
            forecastChart: `${env.apiBaseUrl}/forecast/chart/forecast`
        },
        inventory: {
            approveRecommendation: `${env.apiBaseUrl}/inventory/approve`,
            capacityChart: `${env.apiBaseUrl}/inventory/chart/capacity`,
            defaultGridColumns: `${env.apiBaseUrl}/inventory/default_grid_columns`,
            walkUpOverride: `${env.apiBaseUrl}/inventory/override/walk_up`,
            constraintCheck: `${env.apiBaseUrl}/inventory/check_class_constraint`,
            saveBulkOverride: `${env.apiBaseUrl}/inventory/bulk_override`,
            singleOverride: `${env.apiBaseUrl}/inventory/override`,
            shoulderChart: `${env.apiBaseUrl}/inventory/chart/shoulder_train`,
            shoulderChartLegList: `${env.apiBaseUrl}/inventory/chart/shoulder_train/leg_list`,
            overrideInfo: `${env.apiBaseUrl}/inventory/override_info`,
            heatmap: `${env.apiBaseUrl}/inventory/heatmap`,
            singleTubemap: `${env.apiBaseUrl}/inventory/chart/tube_single_train`,
            reminders: `${env.apiBaseUrl}/inventory/reminders`
        },
        groupSales: {
            grid: `${env.apiBaseUrl}/group_sales`,
            quote: `${env.apiBaseUrl}/group_sales/quote`
        },
        status: {
            grid: `${env.apiBaseUrl}/status_info`,
            lastRun: `${env.apiBaseUrl}/last_run_info`
        }
    };
}
