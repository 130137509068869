import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {InventoryOverrideService} from '../../services/inventory-override.service';
import {Subscription} from 'rxjs';
import {ProfileSelectorService} from '../profile-selector/profile-selector.service';
import {DialogRef} from '@progress/kendo-angular-dialog';
import {OverrideType} from '../../../enums';
import {BaseInventoryOverride} from '../../models/base-inventory-override.model';
import {OverrideLevel} from '../../../../inventory/inventory.model';

@Component({
    selector: 'app-bulk-inventory-override',
    templateUrl: './bulk-inventory-override.component.html',
    styleUrls: ['./bulk-inventory-override.component.scss']
})
export class BulkInventoryOverrideComponent extends BaseInventoryOverride implements OnInit, OnDestroy {
    @Input() bulkRequest: any;
    public loading = true;
    public bulkToggle = false;
    public invOverride: any;
    public title = 'Bulk Override';
    private selectedProfile: any;
    private profileChangeSub: Subscription;

    constructor(public dialog: DialogRef,
                private overrideSvc: InventoryOverrideService,
                private profileSelectSvc: ProfileSelectorService) {
        super(dialog);
        this.profileChangeSub = this.profileSelectSvc.profileChange$.subscribe(this.selectedProfileChange.bind(this));
    }

    ngOnInit(): void {
        this.getOverrideInfo();
    }

    ngOnDestroy(): void {
        this.profileChangeSub.unsubscribe();
    }

    public isValid(): boolean {
        return this.bulkToggle ? this.selectedProfile : super.isValid();
    }

    public onApprove(): void {
        this.saveApprovals();
    }


    public onSave(): void {
        this.saveOverrides();
    }

    private getOverrideInfo(): void {
        this.overrideSvc.getBulkOverrideInfo(this.bulkRequest).subscribe(resp => {
            this.loading = false;
            resp.overrideType = OverrideType.Bulk;
            resp.overrideLevel = this.bulkRequest.overrideLevel;
            resp.passengerClass = this.bulkRequest.passengerClass;
            if (this.bulkRequest.dataItem) {
                resp.origin = this.bulkRequest.dataItem.origin;
                resp.destination = this.bulkRequest.dataItem.destination;
            }

            if (resp.overrideLevel === 'od') {
                resp.journeyPairs = this.bulkRequest.journeyPairs;
            }
            this.invOverride = resp;
        }, error => {
            console.error(`Error when getting override info ${error}`);
            this.dialog.close();
        });
    }

    private saveApprovals(): void {
        this.loading = true;
        this.updateRequest();
        this.invOverride.buckets = [];
        this.overrideSvc.approveRecommendation(this.invOverride).subscribe(resp => {
            if (resp) {
                this.closeDialog();
            } else {
                this.loading = false;
            }
        }, error => {
            console.error(`Error during bulk approve ${error}`);
            this.loading = false;
        }, () => {
            this.updateComplete.emit(true);
        });
    }

    private saveOverrides(): void {
        this.loading = true;
        if (this.bulkToggle && this.selectedProfile) {
            this.invOverride.overrideProfile = this.selectedProfile.id;
        }
        this.updateRequest();
        this.overrideSvc.saveBulkOverride(this.invOverride).subscribe(resp => {
            if (resp) {
                this.closeDialog();
            } else {
                this.loading = false;
            }
        }, error => {
            console.error(`Error during bulk save ${error}`);
            this.loading = false;
        }, () => {
            this.updateComplete.emit(true);
        });
    }

    private selectedProfileChange(profile: any): void {
        this.selectedProfile = profile;
    }

    private updateRequest(): void {
        this.invOverride.additionalFilters = this.bulkRequest.additionalFilters;
        if (this.bulkRequest.overrideLevel === OverrideLevel.Leg) {
            this.invOverride.legOrigin = this.bulkRequest.journeyOrigin[0];
            this.invOverride.legDestination = this.bulkRequest.journeyDestination[0];
        }
    }
}
