import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SharedGrid} from '../../shared/grid.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {WalkUpOverride} from '../inventory.model';

@Component({
    selector: 'app-walk-up-override',
    templateUrl: './walk-up-override.component.html',
    styleUrls: ['./walk-up-override.component.scss']
})
export class WalkUpOverrideComponent implements OnInit {
    public tableGrid = new SharedGrid();
    public active = false;
    public title = 'Walk-up Override';
    public gridData = [];
    public width = 600;
    public numericOverride: number;
    public percentOverride: number;
    public selectAllState = 'unchecked';
    public selectedRows = [];

    @Input()
    public set dataItem(dataItem: any) {
        if (dataItem !== undefined) {
            this.active = true;
            this.gridData = dataItem;
        }
    }

    @Output() save: EventEmitter<WalkUpOverride[]> = new EventEmitter();
    @Output() revert: EventEmitter<WalkUpOverride[]> = new EventEmitter();

    public screenConfig = {
        base: {
            'background-color': '#05053A',
            color: '#FFFFFF'
        }
    };

    constructor(private formBuilder: FormBuilder) {
        this.tableGrid.options.height = 315;
    }

    ngOnInit() {
    }

    public cellClickHandler({sender, rowIndex, column, columnIndex, dataItem, isEdited}) {
        if (!isEdited) {
            sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
        }
    }

    public cellCloseHandler(args: any) {
        const {formGroup, dataItem} = args;
        if (!formGroup.valid) {
            // prevent closing edited cell if invalid values
            args.preventDefault();
        } else if (formGroup.dirty) {
            dataItem.override = formGroup.value.override;
            this.selectRowOnEdit(dataItem);
        }
    }

    public closeDialog(): void {
        this.reset();
    }

    public isBulkInputsDisabled(): boolean {
        return this.selectedRows.length === 0;
    }

    public isRevertDisabled(): boolean {
        if (this.selectedRows.length > 0) {
            return !this.getSelectedRowsData().every(item => item.override !== null &&
                item.override !== undefined);
        }
        return true;
    }

    public isSaveDisabled(): boolean {
        if (this.selectedRows.length > 0) {
            if (this.hasBulkOverrideValues()) {
                return false;
            }
            return this.getSelectedRowsData().some(item => item.override === null ||
                item.override === undefined);
        }
        return true;
    }

    public onCancel(): void {
        this.reset();
    }

    public onRevert(): void {
        this.revert.emit(this.getSelectedRowsData());
        this.reset();
    }

    public onSave(): void {
        let rows = [];
        if (this.hasBulkOverrideValues()) {
            rows = this.setBulkOverrideValues();
        } else {
            rows = this.getSelectedRowsData();
        }
        this.save.emit(rows);
        this.reset();
    }

    public onSelectAllChange(checkedState: any): void {
        if (checkedState === 'checked') {
            this.selectedRows = this.gridData.map((item, index) => index);
        } else {
            this.selectedRows = [];
        }
        this.selectAllState = checkedState;
    }

    public onSelectedKeysChange(): void {
        const len = this.selectedRows.length;
        if (len === 0) {
            this.selectAllState = 'unchecked';
        } else if (len > 0 && len < this.gridData.length) {
            this.selectAllState = 'indeterminate';
        } else {
            this.selectAllState = 'checked';
        }
    }

    public selectionKey(context: any): any {
        return context.index;
    }

    private createFormGroup(dataitem: any): FormGroup {
        return this.formBuilder.group({
            override: dataitem.override
        });
    }

    private getSelectedRowsData(): WalkUpOverride[] {
        return this.gridData.filter((item, idx) => this.selectedRows.includes(idx));
    }

    private hasBulkOverrideValues(): boolean {
        return (this.numericOverride !== null && this.numericOverride !== undefined) ||
            (this.percentOverride !== null && this.percentOverride !== undefined);
    }

    private reset(): void {
        this.active = false;
        this.gridData = [];
        this.selectedRows = [];
        this.selectAllState = 'unchecked';
        this.numericOverride = null;
        this.percentOverride = null;
    }

    private removeSelectedRow(dataItemIndex): void {
        const itemIndex = this.selectedRows.indexOf(dataItemIndex);
        this.selectedRows.splice(itemIndex, 1);
    }

    private selectRowOnEdit(dataItem): void {
        const dataItemIndex = this.gridData.indexOf(dataItem);
        const isSelected = this.selectedRows.includes(dataItemIndex);
        const hasValue = dataItem.override !== null && dataItem.override !== undefined;
        if (isSelected && !hasValue) {
            this.removeSelectedRow(dataItemIndex);
        } else if (!isSelected && hasValue) {
            this.selectedRows.push(dataItemIndex);
        }
    }

    private setBulkOverrideValues(): WalkUpOverride[] {
        const overrides = this.getSelectedRowsData();
        if (this.percentOverride === null || this.percentOverride === undefined) {
            overrides.forEach(o => o.override = this.numericOverride);
        } else {
            overrides.forEach(o => o.override = o.walkUpDemand + (o.walkUpDemand * (this.percentOverride / 100)));
        }
        return overrides;
    }
}
