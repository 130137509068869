<div style="padding-top: 10px;">
    <div class="row">
        <div class="col col-70pct">
            <app-heatmap-container-card [filterOptions]="searchRequest"></app-heatmap-container-card>
        </div>
        <div class="col col-30pct">
            <mat-card class="ra-card" [style.height.px]="440">
                <mat-card-content style="height: 100%;">
                    <div class="spinner-wrapper">
                        <mat-tab-group style="height: 100%;">
                            <mat-tab label="Capacity">
                                <ng-template matTabContent>
                                    <app-capacity-chart [capacityData]="capacityChartData">
                                    </app-capacity-chart>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Shoulder Chart">
                                <ng-template matTabContent>
                                    <div style="text-align: right; height: auto;">
                                        <kendo-dropdownlist
                                                [data]="shoulderTrainDropDownOptions.allValues"
                                                [(value)]="shoulderTrainDropDownOptions.selectedValues"
                                                [popupSettings]="{appendTo: 'component'}"
                                                [textField]="'name'"
                                                [valueField]="'id'"
                                                [style.width.px]="150"
                                                (selectionChange)="onShoulderLegChange($event)">
                                        </kendo-dropdownlist>
                                    </div>
                                    <app-shoulder-chart style="display: block; height: 88%"
                                                        [chartRequest]="shoulderChartRequest"
                                                        [trainType]="'leg'"></app-shoulder-chart>
                                </ng-template>
                            </mat-tab>
                        </mat-tab-group>
                        <div *ngIf="this.shoulderChartLoading" class="k-i-loading"></div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row" style="margin-bottom: 0;">
        <div class="col col-100pct">
            <app-tubemap-container></app-tubemap-container>
        </div>
    </div>
    <div class="row">
        <div class="col col-100pct">
            <mat-card class="ra-card">
                <mat-card-content>
                    <div class="spinner-wrapper">
                        <div class="grid-toolbar">
                            <label [style.font-weight]="'bold'" [style.margin-right.px]="4"
                            >Metrics:
                            </label>
                            <kendo-multiselect
                                    [data]="invMetricColumns.allValues"
                                    [(value)]="invMetricColumns.selectedValues"
                                    [popupSettings]="{appendTo: 'component'}"
                                    [textField]="'name'"
                                    [valueField]="'id'"
                                    [autoClose]="false"
                                    (valueChange)="onValueChange($event)"
                                    class="filter-drop-down multi-select"
                            ></kendo-multiselect>
                            <kendo-dropdownbutton
                                    [data]="updateStatusOptions"
                                    (itemClick)="onUpdateStatusClick($event)"
                                    [disabled]="selectedRows.length === 0"
                            >Bulk Status Update
                            </kendo-dropdownbutton>
                            <button
                                    kendoButton
                                    (click)="displayOverrideDialog('bulk', 'od')"
                                    [disabled]="selectedRows.length === 0"
                            >
                                Bulk Overrides
                            </button>
                            <button kendoButton (click)="excelExport.openDialog()">
                                Export View
                            </button>
                            <button kendoButton style="float: right;"
                                    (click)="doReOpt($event)"
                                    [disabled]="!allowReOpt">
                                Re-Optimize
                            </button>
                        </div>
                        <div>
                            <kendo-grid
                                #invGrid
                                [data]="gridData"
                                [rowClass]="rowCallback"
                                [sortable]="true"
                                [selectable]="tableGrid.selectableSettings"
                                [rowHeight]="tableGrid.options.rowHeight"
                                [height]="tableGrid.options.height"
                                [kendoGridSelectBy]="selectionKey"
                                [sort]="gridSort"
                                (sortChange)="sortChange($event)"
                                [selectedKeys]="selectedRows"
                                [scrollable]="'virtual'"
                                (cellClick)="onCellClick($event)"
                            >
                                <kendo-grid-checkbox-column
                                        *ngIf="tableGrid.options.selectAble"
                                        [headerClass]="'ra-grid-header ra-grid-checkbox'"
                                        [footerClass]="'ra-grid-footer'"
                                        [class]="'ra-grid-checkbox'"
                                        [showSelectAll]="true"
                                        [width]="35"
                                ></kendo-grid-checkbox-column>
                                <kendo-grid-column
                                        title="Status"
                                        field="status"
                                        [width]="65"
                                        [headerClass]="'ra-grid-header'"
                                        [footerClass]="'ra-grid-footer'"
                                        [hidden]="getDefaultGridColumnVal('status')"
                                        [style]="{ 'text-align': 'center' }"
                                >
                                    <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="fa-layers fa-fw">
                      <i class="{{ getStatusIcon(dataItem.status) }}"></i>
                    </span>
                                    </ng-template>
                                    <ng-template kendoGridFooterTemplate>
                                        Total
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="BR" field="businessRuleIndicator"
                                                   [width]="50" [headerClass]="'ra-grid-header'"
                                                   [footerClass]="'ra-grid-footer'"
                                                   [hidden]="getDefaultGridColumnVal('businessRuleIndicator')"
                                                   [style]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="fa-layers fa-fw">
                            <i class="{{getBusinessRuleIcon(dataItem.businessRuleIndicator)}}"></i>
                        </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column
                                        title="Journey"
                                        field="journey"
                                        [headerClass]="'ra-grid-header'"
                                        [footerClass]="'ra-grid-footer'"
                                        [hidden]="getDefaultGridColumnVal('journey')"
                                ></kendo-grid-column>
                                <kendo-grid-column
                                        title="DP"
                                        field="daypart"
                                        [headerClass]="'ra-grid-header'"
                                        [footerClass]="'ra-grid-footer'"
                                        [hidden]="getDefaultGridColumnVal('daypart') || checkHiddenColumn('daypart')"
                                ></kendo-grid-column>
                                <kendo-grid-column
                                        title="Fcst Conf"
                                        field="forecastConfidence"
                                        [hidden]="getDefaultGridColumnVal('forecastConfidence') || checkHiddenColumn('forecastConfidence')"
                                        [headerClass]="'ra-grid-header'"
                                        [footerClass]="'ra-grid-footer'"
                                        [style]="{ 'text-align': 'center' }"
                                >
                                    <ng-template kendoGridCellTemplate let-dataItem>
                    <span
                            class="{{
                        getConfidenceIcon(dataItem.forecastConfidence)
                      }}"
                    ></span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column
                                        title="OTB Δ"
                                        field="intradayOTB"
                                        [width]="80"
                                        [headerClass]="['ra-grid-header', 'col-pull-right']"
                                        [footerClass]="['ra-grid-footer', 'col-pull-right']"
                                        [hidden]="getDefaultGridColumnVal('intradayOTB') || checkHiddenColumn('intradayOTB')"
                                        [class]="{'col-pull-right': true}">
                                    <ng-template kendoGridFooterTemplate>
<!--                                    intradayOTB value is the delta of intraday OTB- overnight OTB -->
                                        {{(totalData?.intradayOTB.sum)}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column
                                        title="OTB"
                                        field="otbBookings"
                                        [width]="80"
                                        [headerClass]="['ra-grid-header', 'col-pull-right']"
                                        [footerClass]="['ra-grid-footer', 'col-pull-right']"
                                        [hidden]="getDefaultGridColumnVal('otbBookings')"
                                        [class]="{'col-pull-right': true}">
                                    <ng-template kendoGridFooterTemplate>
                                        {{totalData?.otbBookings.sum}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="Flex" field="flexDemand"
                                                   [headerClass]="['ra-grid-header', 'col-pull-right']"
                                                   [footerClass]="['ra-grid-footer', 'col-pull-right']"
                                                   [class]="{'col-pull-right': true}"
                                                   [hidden]="getDefaultGridColumnVal('flexDemand')"
                                                   format="{0:n0}">
                                    <ng-template kendoGridFooterTemplate>
                                        {{totalData?.flexDemand.sum | number:'1.0-0'}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column
                                        title="Rem Dmd"
                                        field="totalRecDemand"
                                        [headerClass]="['ra-grid-header', 'col-pull-right']"
                                        [footerClass]="['ra-grid-footer', 'col-pull-right']"
                                        [hidden]="getDefaultGridColumnVal('totalRecDemand')"
                                        [class]="{'col-pull-right': true}"
                                        format="{0:n0}">
                                    <ng-template kendoGridFooterTemplate>
                                        {{totalData?.totalRecDemand.sum | number:'1.0-0'}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="Excess" field="excessAllocations"
                                                   [headerClass]="['ra-grid-header', 'col-pull-right']"
                                                   [footerClass]="['ra-grid-footer', 'col-pull-right']"
                                                   [class]="{'col-pull-right': true}"
                                                   [hidden]="getDefaultGridColumnVal('excessAllocations')"
                                                   format="{0:n0}">
                                    <ng-template kendoGridHeaderTemplate let-column>
                                        <div class="ra-toggle-column" (contextmenu)="toggleExcessColumn($event, column.field)">
                                            {{column.title}}
                                        </div>
                                    </ng-template>
                                    <ng-template kendoGridFooterTemplate>
                                        {{totalData?.excessAllocations.sum | number:'1.0-0'}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="Max Excess" field="maxExcess"
                                                   [headerClass]="['ra-grid-header', 'col-pull-right']"
                                                   [footerClass]="['ra-grid-footer', 'col-pull-right']"
                                                   [class]="{'col-pull-right': true}"
                                                   [hidden]="!showMaxExcess || getDefaultGridColumnVal('maxExcess')"
                                                   format="{0:n0}">
                                    <ng-template kendoGridHeaderTemplate let-column>
                                        <div class="ra-toggle-column" (contextmenu)="toggleExcessColumn($event, column.field)">
                                            {{column.title}}
                                        </div>
                                    </ng-template>
                                    <ng-template kendoGridFooterTemplate>
                                        {{totalData?.maxExcess.sum | number:'1.0-0'}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column
                                        title="Alloc"
                                        field="totalAllocations"
                                        [headerClass]="['ra-grid-header', 'col-pull-right']"
                                        [footerClass]="['ra-grid-footer', 'col-pull-right']"
                                        [hidden]="getDefaultGridColumnVal('totalAllocations')"
                                        [class]="{'col-pull-right': true}">
                                    <ng-template kendoGridFooterTemplate>
                                        {{totalData?.totalAllocations.sum}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="Available" field="availableAllocations"
                                                   [headerClass]="['ra-grid-header', 'col-pull-right']"
                                                   [footerClass]="['ra-grid-footer', 'col-pull-right']"
                                                   [class]="{'col-pull-right': true}"
                                                   [hidden]="getDefaultGridColumnVal('availableAllocations')"
                                                   format="{0:n0}">
                                    <ng-template kendoGridFooterTemplate>
                                        {{totalData?.availableAllocations.sum | number:'1.0-0'}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column
                                        title="Sensitivity"
                                        field="sensitivity"
                                        [class]="{ sensitivityColumn: true }"
                                        [headerClass]="'ra-grid-header'"
                                        [footerClass]="'ra-grid-footer'"
                                        [hidden]="getDefaultGridColumnVal('sensitivity')"
                                ></kendo-grid-column>
                                <kendo-grid-column
                                        title="Comp Pct Diff"
                                        field="compPctDiff"
                                        format="{0:p}"
                                        [headerClass]="['ra-grid-header', 'col-pull-right']"
                                        [footerClass]="['ra-grid-footer', 'col-pull-right']"
                                        [class]="{'col-pull-right': true}"
                                        [hidden]="
                    checkHiddenColumn('compPctDiff') || !showCompPriceDiff || getDefaultGridColumnVal('compPctDiff')
                "
                                >
                                    <ng-template kendoGridHeaderTemplate let-column>
                    <span (contextmenu)="toggleColumn($event, column.field)">{{
                        column.title
                        }}</span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column
                                        title="Comp Price Diff"
                                        field="compPriceDiff"
                                        [headerClass]="['ra-grid-header', 'col-pull-right']"
                                        [footerClass]="['ra-grid-footer', 'col-pull-right']"
                                        [class]="{'col-pull-right': true}"
                                        [hidden]="
                    checkHiddenColumn('compPriceDiff') || showCompPriceDiff || getDefaultGridColumnVal('compPriceDiff')
                  "
                                >
                                    <ng-template kendoGridHeaderTemplate let-column>
                    <span (contextmenu)="toggleColumn($event, column.field)">{{
                        column.title
                        }}</span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column
                                        title="Curr Profile"
                                        field="currentProfile"
                                        [headerClass]="'ra-grid-header'"
                                        [footerClass]="'ra-grid-footer'"
                                        [class]="{'col-clickable': true}"
                                        [hidden]="getDefaultGridColumnVal('currentProfile')"
                                >
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <div (click)="displayOverrideDialog('single', 'od', dataItem)">
                      <span>
                        {{
                          dataItem.currentProfile
                              ? dataItem.currentProfile
                              : "N/A"
                          }}
                      </span>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column
                                        title="FV"
                                        field="recommendedProfile"
                                        [headerClass]="'ra-grid-header'"
                                        [footerClass]="'ra-grid-footer'"
                                        [hidden]="getDefaultGridColumnVal('recommendedProfile')"
                                ></kendo-grid-column>
                                <kendo-grid-column
                                        title="Lead Bucket"
                                        field="leadBucket"
                                        [headerClass]="'ra-grid-header'"
                                        [footerClass]="'ra-grid-footer'"
                                        [hidden]="getDefaultGridColumnVal('leadBucket')"
                                ></kendo-grid-column>
                                <kendo-grid-column
                                        title="OLB"
                                        field="ovntLeadBucket"
                                        [headerClass]="'ra-grid-header'"
                                        [footerClass]="'ra-grid-footer'"
                                        [hidden]="getDefaultGridColumnVal('ovntLeadBucket') || checkHiddenColumn('ovntLeadBucket')"
                                ></kendo-grid-column>
                                <kendo-grid-column
                                        title="Reason"
                                        field="reason"
                                        [headerClass]="'ra-grid-header'"
                                        [footerClass]="'ra-grid-footer'"
                                        [hidden]="getDefaultGridColumnVal('reason')"
                                ></kendo-grid-column>
                                <kendo-grid-column title="Ref Price" field="refPrice"
                                                   [headerClass]="['ra-grid-header', 'col-pull-right']"
                                                   [footerClass]="['ra-grid-footer', 'col-pull-right']"
                                                   [class]="{'col-pull-right': true}"
                                                   [hidden]="getDefaultGridColumnVal('refPrice')"
                                                   format="{0:c2}"></kendo-grid-column>
                                <kendo-grid-column
                                        title="Proj Yield"
                                        field="projectedYield"
                                        [headerClass]="['ra-grid-header', 'col-pull-right']"
                                        [footerClass]="['ra-grid-footer', 'col-pull-right']"
                                        [class]="{'col-pull-right': true}"
                                        [hidden]="getDefaultGridColumnVal('projectedYield')"
                                >
                                    <ng-template kendoGridCellTemplate let-dataItem>
                    <span>
                      {{ dataItem.projectedYield | kendoNumber:'c2'}}
                    </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column
                                        title="Proj Revenue"
                                        field="projectedRevenue"
                                        [headerClass]="['ra-grid-header', 'col-pull-right']"
                                        [footerClass]="['ra-grid-footer', 'col-pull-right']" format="{0:n0}"
                                        [class]="{'col-pull-right': true}"
                                        [hidden]="getDefaultGridColumnVal('projectedRevenue')"
                                >
                                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span>
                            {{ dataItem.projectedRevenue | kendoNumber:'c0'}}
                        </span>
                                    </ng-template>
                                    <ng-template kendoGridFooterTemplate>
                                        {{totalData?.projectedRevenue.sum | kendoNumber:'c0'}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column
                                        title="Rev Pacing"
                                        field="revenuePacing"
                                        [hidden]="getDefaultGridColumnVal('revenuePacing') || checkHiddenColumn('revenuePacing')"
                                        [headerClass]="['ra-grid-header', 'col-pull-right']"
                                        [footerClass]="['ra-grid-footer', 'col-pull-right']"
                                        [class]="{'col-pull-right': true}"
                                >
                                    <ng-template kendoGridCellTemplate let-dataItem>
                    <span>
                      {{ dataItem.revenuePacing | kendoNumber:'p' }}
                    </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="Last Updated" field="lastUpdated"
                                                   [headerClass]="'ra-grid-header'"
                                                   [footerClass]="'ra-grid-footer'"
                                                   [hidden]="getDefaultGridColumnVal('lastUpdated')"
                                ></kendo-grid-column>
                            </kendo-grid>
                            <app-excel-export
                                #excelExport
                                [gridData]="gridData"
                                [gridColumns]="gridColumns"
                                [filterData]="searchRequest"
                                [filterColumns]="filterColumns"
                            ></app-excel-export>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <div *ngIf="this.invGridLoading" class="k-i-loading"></div>
        </div>
    </div>
    <app-walk-up-override [dataItem]="walkUpDemandData"
                          (save)="walkUpOverrideSave($event)"
                          (revert)="walkUpOverrideRevert($event)"
    ></app-walk-up-override>
    <kendo-contextmenu
        #gridMenu
        [items]="updateStatusOptions"
        (select)="processGridMenuItemClick($event)"
    ></kendo-contextmenu>
</div>
<ng-container #dialogContainer></ng-container>
