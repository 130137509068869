import {Component, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FilterService} from '../shared/filter.service';
import {Subscription} from 'rxjs/Subscription';
import {TubeMapModel} from '../tubemap/tubemap.model';
import {InventoryService} from './inventory.service';
import {BaseSearchRequest, FilterItem} from '../shared/filter-item.model';
import {SharedGrid} from '../shared/grid.model';
import {
    BulkApprovalRequest,
    InventoryGridRequest,
    InventoryModel,
    InventoryOverride,
    OverrideLevel,
    ShoulderTrainRequest,
    SingleApprovalRequest,
    WalkUpOverride
} from './inventory.model';
import {BusinessRuleIndicator, OverrideType, StatusType} from '../shared/enums';
import {RowArgs, RowClassArgs} from '@progress/kendo-angular-grid';
import {ContextMenuComponent} from '@progress/kendo-angular-menu';
import {LoadMaskService} from '../shared/load-mask-service';
import {
    aggregateBy,
    AggregateDescriptor,
    orderBy,
    SortDescriptor
} from '@progress/kendo-data-query';
import {DateUtils} from '../shared/date-utils';
import {ReOptimizationService} from '../core/services/reopt/re-optimization.service';
import {OverrideDialogService} from '../shared/inventory-override/services/override-dialog.service';
import {HeatmapService} from './services/heatmap.service';
import {SharedChartMenuAction, SharedChartMenuEvent} from './models/shared-chart.model';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/internal/observable/of';

@Component({
    selector: 'app-inventory',
    templateUrl: './inventory.component.html',
    styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit, OnDestroy {

    private static staticObject: InventoryComponent;
    @ViewChild('dialogContainer', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;
    @ViewChild('gridMenu', {static: false}) public gridContextMenu: ContextMenuComponent;
    @ViewChild('excelExport', {static: false}) public excelExport: any;
    public northTube: TubeMapModel;
    public southTube: TubeMapModel;
    public invMetricColumns = new FilterItem();
    public inventoryFlags = new FilterItem();
    public tableGrid = new SharedGrid();
    public gridData: InventoryModel[];
    public totalData: any;
    public overrideItem: InventoryOverride;
    public selectedShoulderTrainData: any;
    public capacityChartData: any;
    public selectedRows: any[] = [];
    public currentChart = 'northBound';
    public updateStatusOptions = [{id: 'Approved', text: 'Approve'}];
    public shoulderTrainDropDownOptions = new FilterItem();
    public walkUpDemandData: any;
    public isFilterExpanded: boolean;
    public shoulderChartRequest: any;

    public searchRequest: BaseSearchRequest;
    public filterColumns: any[] = [
        {field: 'startDate', title: 'Start Date'},
        {field: 'endDate', title: 'End Date'},
        {field: 'startDateRange', title: 'Start Range'},
        {field: 'endDateRange', title: 'End Range'},
        {field: 'passengerClass', title: 'Passenger Class'},
        {field: 'portfolio', title: 'Portfolio'},
        {field: 'origin', title: 'Origin'},
        {field: 'destination', title: 'Destination'},
        {field: 'dayOfWeek', title: 'Day of Week'},
        {field: 'rsid', title: 'RSID'},
        {field: 'recordStatus', title: 'Record Status'},
        {field: 'viewType', title: 'View Type'},
        {field: 'viewLevel', title: 'View Level'},
        {field: 'journeyType', title: 'Journey Type'},
        {field: 'daypart', title: 'Daypart'}
    ];
    public gridColumns: any[] = [
        {field: 'rsid', title: 'RSID'},
        {field: 'departureDate', title: 'Departure Date'},
        {field: 'status', title: 'Status'},
        {field: 'origin', title: 'Origin'},
        {field: 'destination', title: 'Destination'},
        {field: 'daypart', title: 'DP'},
        {field: 'forecastConfidence', title: 'Fcst. Conf'},
        {field: 'intradayOTB', title: 'OTB Δ'},
        {field: 'otbBookings', title: 'OTB'},
        {field: 'totalRecDemand', title: 'Rem Dmd'},
        {field: 'excessAllocations', title: 'Excess', cellOpt: {format: '#,##0'}},
        {field: 'maxExcess', title: 'Max Excess'},
        {field: 'totalAllocations', title: 'Alloc', cellOpt: {format: '#,##0'}},
        {field: 'availableAllocations', title: 'Available', cellOpt: {format: '#,##0'}},
        {field: 'sensitivity', title: 'Sensitivity'},
        {field: 'compPriceDiff', title: 'Competition Price Difference'},
        {field: 'flexDemand', title: 'Flex', cellOpt: {format: '#,##0'}},
        {field: 'currentProfile', title: 'Curr Profile'},
        {field: 'recommendedProfile', title: 'FV'},
        {field: 'leadBucket', title: 'Lead Bucket'},
        {field: 'ovntLeadBucket', title: 'OLB'},
        {field: 'reason', title: 'Reason'},
        {field: 'refPrice', title: 'Ref Price', cellOpt: {format: '#,##0.00'}},
        {field: 'projectedYield', title: 'Proj Yield', cellOpt: {format: '#,##0.00'}},
        {field: 'projectedRevenue', title: 'Proj Revenue', cellOpt: {format: '#,##0.00'}},
        {field: 'revenuePacing', title: 'Rev Pacing', cellOpt: {format: '#,##0%'}},
        {field: 'lastUpdated', title: 'Last Updated'}
    ];

    private applyFilterSubscription: Subscription;
    private filterExpandSubscription: Subscription;
    private heatmapMenuClickSubscription: Subscription;
    private contextItem: any;
    private filterLayout = {
        screenName: 'inventory',
        journeyFilterConfig: {
            dropDownType: 'multi',
            isRequired: false
        },
        hidden: [
            {name: 'viewLevelSwitch'},
            {name: 'journeyTypeData'},
            {name: 'daypartData'},
            {name: 'viewTypeData'},
            {name: 'timeData'},
            {name: 'groupSize'}
        ],
        clear: ['statusTypeData']
    };
    tubeChartLoading = false;
    shoulderChartLoading = false;
    invGridLoading = false;
    lastInvGridRequest: any;
    public gridSort: SortDescriptor[] = [];
    public selectedInvFlag = null;
    public showCompPriceDiff = false;
    public showMaxExcess = false;
    private totalAggregates: AggregateDescriptor[] = [
        {field: 'otbBookings', aggregate: 'sum'},
        {field: 'intradayOTB', aggregate: 'sum'},
        {field: 'totalRecDemand', aggregate: 'sum'},
        {field: 'excessAllocations', aggregate: 'sum'},
        {field: 'maxExcess', aggregate: 'sum'},
        {field: 'totalAllocations', aggregate: 'sum'},
        {field: 'availableAllocations', aggregate: 'sum'},
        {field: 'flexDemand', aggregate: 'sum'},
        {field: 'projectedRevenue', aggregate: 'sum'}
    ];
    public allowReOpt = false;
    public defaultGridColumns = {};

    constructor(private filterService: FilterService, private inventoryService: InventoryService, private loadMaskServ: LoadMaskService,
                private reOptService: ReOptimizationService, private overrideDialogSvc: OverrideDialogService,
                private heatmapSvc: HeatmapService) {
        InventoryComponent.staticObject = this;
        this.applyFilterSubscription = this.filterService.filterTriggered$.subscribe(this.onFiltersChange.bind(this));
        this.filterService.updateFilterLayout(this.filterLayout);
        this.tableGrid.gridTitle = 'Optimization Outputs';
        this.filterExpandSubscription = this.filterService.filterExpand$.subscribe(this.onFilterExpand.bind(this));
        this.isFilterExpanded = this.filterService.isFilterExpanded;
        this.heatmapMenuClickSubscription = this.heatmapSvc.heatmapMenuClick$.subscribe(this.onHeatmapMenuClick.bind(this));
        this.getDefaultGridColumns();

    }

// when a new filter is saved or existing filter is updated we assign the saved selected columns to invMetricColumns selected values.
    static loadSelectedColumns(values: any[]) {
        InventoryComponent.staticObject.invMetricColumns.selectedValues = JSON.parse(localStorage.getItem('invGridSelectedColumns'));
    }

    ngOnInit() {
        this.inventoryFlags.loadAllValues(this.inventoryService.getInventoryFlags());
        this.invMetricColumns.loadAllValues(this.getOptionalMetricsColumns());
    }

    ngOnDestroy() {
        this.applyFilterSubscription.unsubscribe();
        this.heatmapMenuClickSubscription.unsubscribe();
    }

    private getOptionalMetricsColumns(): Observable<any> {
        const dataValues = [];
        const key = 'default';
        const name = 'name';
        const id = 'id';
        // tslint:disable-next-line:forin
        for (const val in this.defaultGridColumns) {
            const value = this.defaultGridColumns[val];
            if (!value[key]) {
                dataValues.push({id: val, name: value[name]});
                this.defaultGridColumns[val].default = true;
            }
        }
        return of({
            data: dataValues
        });
    }

    private onFiltersChange(searchRequest) {
        this.clearAllComponentData();
        this.searchRequest = searchRequest;
    }

    public checkHiddenColumn(columnName): boolean {
        if (!columnName) {
            return false;
        }
        return this.invMetricColumns.selectedValues.findIndex(i => i.id === columnName) === -1;
    }

    public doReOpt(event: any): void {
        const requestArgs = {portfolios: this.searchRequest.portfolio};
        this.invGridLoading = true;
        this.reOptService.initiateReOptimization(requestArgs).subscribe(results => {
            if (results.errors.length > 0) {
                alert(results.errors);
            }
            this.invGridLoading = false;
        }, error => {
            console.error(error);
            this.invGridLoading = false;
        });
    }

    public toggleExcessColumn(event: any, field: any): void {
        event.preventDefault();
        this.showMaxExcess = !this.showMaxExcess;
    }

    public tubeMapHandler(event: any) {
        if (event.action === 'Override Advance') {
            const overrideLvl = event.data.origin === '' ? OverrideLevel.Train : OverrideLevel.Leg;
            this.displayBulkOverride(overrideLvl, event.data);
        } else if (event.action === 'Toggle Auto-Pilot') {
            this.enableAutoPilot(event.data);
        } else if (event.action === 'Override Walk-Up') {
            this.displayWalkUpDialog(this.buildWalkUpRequest(event.data));
        } else {
            this.getGridData(event.data);
            this.getShoulderChartData(event.data);
            this.getCapacityChart(event.data);
        }
    }

    public getGridData(request: any) {
        this.invGridLoading = true;
        this.lastInvGridRequest = request;
        this.tableGrid.gridTitle = this.buildGridTitle(request);
        this.inventoryService.getInventoryResults(this.buildGridRequest(request)).subscribe(data => {
                this.gridData = data.map(d => {
                    d.lastUpdated = d.lastUpdated ? DateUtils.convertToLocaleDateString(d.lastUpdated as string) : null;
                    d.journey = `${d.origin}-${d.destination}`;
                    return d;
                });
                if (this.gridData && this.gridData.length > 0) {
                    this.totalData = aggregateBy(this.gridData, this.totalAggregates);
                } else {
                    this.totalData = null;
                }
                this.invGridLoading = false;
                this.gridSort = [];
                this.selectedRows = [];
            },
            error => {
                this.invGridLoading = false;
            });
    }

    public getShoulderChartData(request: any) {
        const tubeInfo = this.getTubeInfo(request);
        this.selectedShoulderTrainData = tubeInfo;
        let legs = [];
        if (request.origin !== '') {
            legs = [{
                id: 0,
                name: `${request.origin}-${request.destination}`,
                legOrigin: request.origin,
                legDestination: request.destination
            }];
        } else {
            legs = tubeInfo.segments.map((s, index) => ({
                id: index,
                name: `${s.origin}-${s.destination}`,
                legOrigin: s.origin,
                legDestination: s.destination
            }));
        }
        this.shoulderTrainDropDownOptions.allValues = legs;
        this.shoulderTrainDropDownOptions.selectedValues = legs[0];
        this.onShoulderLegChange(legs[0]);
    }

    public getShoulderChart(request: any): void {
        this.selectedShoulderTrainData = request;
        if (request.origin && request.destination) {
            const legs = [{
                id: 0,
                name: `${request.origin}-${request.destination}`,
                legOrigin: request.origin,
                legDestination: request.destination
            }];
            this.shoulderTrainDropDownOptions.allValues = legs;
            this.shoulderTrainDropDownOptions.selectedValues = legs[0];
            this.onShoulderLegChange(legs[0]);
        } else {
            this.inventoryService.getShoulderTrainLegList(request.rsid, request.effDepartureDate).subscribe(resp => {
                this.shoulderTrainDropDownOptions.allValues = resp;
                this.shoulderTrainDropDownOptions.selectedValues = resp[0];
                this.onShoulderLegChange(resp[0]);
            }, error => {
                console.error(`Error occurred when populating shoulder chart leg list ${error}`);
            });
        }
    }

    public onShoulderLegChange(selectedLeg: any): void {
        this.shoulderChartLoading = true;
        this.shoulderChartRequest = this.buildShoulderChartRequest(selectedLeg, true);
    }

    public displayOverrideDialog(overrideType: string | OverrideType,
                                 overrideLevel: string | OverrideLevel,
                                 dataItem?: any): void {
        localStorage.setItem('showHidden', '0');
        if (overrideType === OverrideType.Bulk) {
            this.displayBulkOverride(overrideLevel, dataItem);
        } else {
            this.displaySingleOverride(dataItem);
        }
    }

    public processGridMenuItemClick(event: any): void {
        this.updateStatus(event.item.id, this.contextItem);
    }

    public onUpdateStatusClick(recordStatus: any): void {
        this.updateStatus(recordStatus.id);
    }

    public onCellClick(event: any): void {
        if (event.type === 'contextmenu') {
            const ogEvent = event.originalEvent;
            ogEvent.preventDefault();
            this.contextItem = event.dataItem;
            this.gridContextMenu.show({left: ogEvent.pageX, top: ogEvent.pageY});
        }
    }

    public selectionKey(context: RowArgs): any {
        return context.index;
    }

    public getConfidenceIcon(confidenceLevel: number): string {
        let cssClasses = 'fas fa-lg ';
        if (confidenceLevel === 1) {
            cssClasses += 'fa-minus low-color';
        } else if (confidenceLevel === 2) {
            cssClasses += 'fa-grip-lines medium-color';
        } else {
            cssClasses += 'fa-bars high-color';
        }
        return cssClasses;
    }

    public getBusinessRuleIcon(businessRuleIndicator: string): string {
        let cssClasses = 'fa-lg ';

        switch (businessRuleIndicator) {
            case BusinessRuleIndicator.Pending:
                cssClasses += 'far fa-circle medium-color';
                break;
            case BusinessRuleIndicator.Active:
                cssClasses += 'fas fa-circle high-color';
                break;
            default:
                cssClasses += '';
                break;
        }
        return cssClasses;
    }

    public getStatusIcon(status: string): string {
        let cssClasses = 'fas fa-lg ';

        switch (status) {
            case StatusType.Active:
                cssClasses = '';
                break;
            case StatusType.NeedsApproval:
                cssClasses += 'fa-bell';
                break;
            case StatusType.New:
                cssClasses += 'fa-plus';
                break;
            case StatusType.OverrideIgnore:
                cssClasses += 'fa-eye-slash';
                break;
            case StatusType.OverrideWatch:
                cssClasses += 'fa-eye';
                break;
            case StatusType.UserFlag:
                cssClasses += 'fa-flag';
                break;
            case StatusType.Approve:
                cssClasses += 'fa-check';
                break;
            case StatusType.ReOptimized:
                cssClasses += 'fa-retweet';
                break;
            default:
                cssClasses = '';
                break;
        }
        return cssClasses;
    }

    public rowCallback(context: RowClassArgs): any {
        switch (context.dataItem.sensitivity) {
            case 'L':
                return {low: true};
            case 'M':
                return {medium: true};
            case 'H':
                return {high: true};
            default:
                return {};
        }
    }

    public chartChange(event): void {
        switch (event.index) {
            case 0:
                this.currentChart = 'northBound';
                break;
            default:
                this.currentChart = 'southBound';
                break;
        }
    }

    public walkUpOverrideSave(walkUpOverrides: WalkUpOverride[]): void {
        this.inventoryService.saveWalkUpOverride(walkUpOverrides).subscribe(results => {
            this.getTubeData();
        }, error => {
            console.error(`Error when saving walk-up overrides ${error}`);
        });
    }

    public walkUpOverrideRevert(walkUpOverrides: WalkUpOverride[]): void {
        this.inventoryService.revertWalkUpOverride(walkUpOverrides).subscribe(results => {
            this.getTubeData();
        }, error => {
            console.error(`Error when reverting walk-up overrides: ${error}`);
        });
    }

    private updateStatus(recordStatus: string, record?: any): void {
        const statusOverrideRequest = this.buildStatusOverrideRequest(recordStatus, record);
        if (recordStatus === 'Approved') {
            this.approveRecommendations(statusOverrideRequest);
        }
    }

    private approveRecommendations(approveRequest: SingleApprovalRequest | BulkApprovalRequest) {
        this.invGridLoading = true;
        this.inventoryService.approveRecommendation(approveRequest).subscribe(() => {
            if (this.lastInvGridRequest) {
                this.getGridData(this.lastInvGridRequest);
            } else {
                this.invGridLoading = false;
            }
        }, error => {
            this.invGridLoading = false;
            console.log(`Error during approval: ${error}`);
        });
    }

    private enableAutoPilot(request: any) {
        this.inventoryService.updateAutoPilot(request).subscribe(result => {
            if (!result) {
                console.error(`Error updating auto pilot status for ${request}`);
            }
        });
    }

    private buildGridTitle(request: any) {
        let title = `${request.rsid} ${DateUtils.convertToLocaleDateString(request.departureDate)}`;
        if (request.origin && request.destination) {
            title += ` (${request.origin} - ${request.destination})`;
        }
        return title;
    }

    private displaySingleOverride(dataItem: any) {
        console.log('invwb - effDepartureDate: ' + dataItem.departureDate);
        const dialog = this.overrideDialogSvc.openSingleDialog(
            this.buildOverrideRequest(dataItem, OverrideType.Single),
            this.dialogContainer);
        dialog.updateComplete.subscribe(resp => {
            if (this.lastInvGridRequest) {
                this.getGridData(this.lastInvGridRequest);
            }
        });
    }

    private displayBulkOverride(overrideLevel: string | OverrideLevel, dataItem: any) {
        const bulkDialogRequest = this.buildOverrideRequest(dataItem, OverrideType.Bulk, overrideLevel);
        const dialog = this.overrideDialogSvc.openBulkDialog(bulkDialogRequest, this.dialogContainer);
        dialog.updateComplete.subscribe(() => {
            if (this.lastInvGridRequest) {
                this.getGridData(this.lastInvGridRequest);
            }
        });
    }

    private buildGridRequest(request: any): InventoryGridRequest {
        const gridRequest = new InventoryGridRequest();
        gridRequest.rsid = request.rsid;
        gridRequest.departureDate = request.departureDate;
        gridRequest.effDepartureDate = request.effDepartureDate;
        gridRequest.journeyOrigin = this.searchRequest.origin;
        gridRequest.journeyDestination = this.searchRequest.destination;
        gridRequest.legOrigin = request.origin;
        gridRequest.legDestination = request.destination;
        gridRequest.passengerClass = this.searchRequest.passengerClass;
        gridRequest.recordStatus = this.searchRequest.recordStatus;
        return gridRequest;
    }

    private buildCapacityChartRequest(data: any): any {
        return {
            rsid: data.rsid,
            effDepartureDate: data.effDepartureDate,
            passengerClass: this.searchRequest.passengerClass
        };
    }

    private buildShoulderChartRequest(trainInfo: any, isLeg: boolean): any {
        const request: ShoulderTrainRequest = {
            shoulderRsid: this.selectedShoulderTrainData.rsid,
            effDepartureDate: this.selectedShoulderTrainData.effDepartureDate,
            passengerClass: this.searchRequest.passengerClass
        };
        if (isLeg) {
            request.legOrigin = trainInfo.legOrigin;
            request.legDestination = trainInfo.legDestination;
        } else {
            request.journeyOrigin = trainInfo.origin;
            request.journeyDestination = trainInfo.destination;
        }
        return request;
    }

    private buildOverrideRequest(request: any, overrideType: OverrideType, overrideLevel?: string | OverrideLevel): any {
        const overrideRequest: any = {};
        if (request) {
            overrideRequest.rsid = request.rsid;
            overrideRequest.departureDate = request.departureDate;
            overrideRequest.effDepartureDate = request.effDepartureDate;
            overrideRequest.journeyOrigin = [request.origin];
            overrideRequest.journeyDestination = [request.destination];
            overrideRequest.daypart = request.daypart;
        } else {
            overrideRequest.rsid = this.gridData[0].rsid;
            overrideRequest.departureDate = this.gridData[0].departureDate;
            overrideRequest.effDepartureDate = this.gridData[0].effDepartureDate;
            overrideRequest.journeyOrigin = [];
            overrideRequest.journeyDestination = [];
        }
        if (overrideType === OverrideType.Bulk && overrideLevel === OverrideLevel.OD) {
            overrideRequest.journeyPairs = this.selectedRows.map(row => {
                return {
                    origin: this.gridData[row].origin,
                    destination: this.gridData[row].destination
                };
            });
        }
        overrideRequest.overrideLevel = overrideLevel;
        overrideRequest.passengerClass = this.searchRequest.passengerClass;
        overrideRequest.recordStatus = this.searchRequest.recordStatus;
        overrideRequest.overrideType = overrideType;
        overrideRequest.additionalFilters = this.searchRequest;
        return overrideRequest;
    }

    private buildStatusOverrideRequest(recordStatus: string, record?: any): any {
        const statusOverrideRequest: any = {};
        if (record === null || record === undefined) {
            const rows = this.getSelectedRows();
            if (rows.length === 0) {
                return;
            }
            record = rows[0];
            statusOverrideRequest.journeyPairs = rows.map(row => {
                return {
                    origin: row.origin,
                    destination: row.destination
                };
            });
        } else {
            statusOverrideRequest.journeyPairs = [{
                origin: record.origin,
                destination: record.destination
            }];
        }
        statusOverrideRequest.rsid = record.rsid;
        statusOverrideRequest.departureDate = record.departureDate;
        statusOverrideRequest.effDepartureDate = record.effDepartureDate;
        statusOverrideRequest.passengerClass = record.passengerClass;
        statusOverrideRequest.additionalFilters = this.searchRequest;
        statusOverrideRequest.recordStatus = recordStatus;
        return statusOverrideRequest;
    }

    private buildWalkUpRequest(request: any): any {
        const walkUpRequest: any = {};
        if (request) {
            walkUpRequest.rsid = request.rsid;
            walkUpRequest.effDepartureDate = request.effDepartureDate;
            walkUpRequest.passengerClass = this.searchRequest.passengerClass;
        }
        return walkUpRequest;
    }

    private clearAllComponentData(): void {
        this.selectedShoulderTrainData = null;
        this.shoulderTrainDropDownOptions = new FilterItem();
        this.shoulderChartRequest = null;
        this.gridData = null;
        this.totalData = null;
        this.capacityChartData = null;

        // first use the selected columns if we are using a saved filter
        let selectedColumns = JSON.parse(localStorage.getItem('invGridSelectedColumns'));

        // if those are empty, use whatever we currently have selected
        if (selectedColumns.length === 0) {
            selectedColumns = JSON.parse(localStorage.getItem('selectedInventoryGridColumns'));
        }
        this.invMetricColumns.selectedValues = selectedColumns;
    }

    private displayWalkUpDialog(request: any): void {
        this.reOptService.checkReOptStatus().subscribe(r => {
            this.inventoryService.getWalkUpDemandData(request).subscribe(results => {
                this.walkUpDemandData = results;
            });
        }, error => {
            alert(error.message);
        });
    }

    private getSelectedRows(): any[] {
        const rows = [];
        this.selectedRows.forEach(sr => {
            const row = this.gridData[sr];
            rows.push(row);
        });
        return rows;
    }

    private convertToDate(dateString: string): Date {
        const tempDate = new Date(dateString);
        return new Date(tempDate.setDate(tempDate.getDate() + 1));
    }

    private getCapacityChart(data: any): void {
        const chartRequest = this.buildCapacityChartRequest(data);
        this.inventoryService.getCapacityChart(chartRequest).subscribe(results => {
            this.capacityChartData = results;
        }, error => {
            console.log(`Error retrieving capacity chart data: ${error}`);
        });
    }

    private getDefaultGridColumns(): void {
        this.inventoryService.getDefaultGridColumns().subscribe(results => {
            // tslint:disable-next-line:only-arrow-functions
            const resultValues = results.reduce(function(result, item) {
                const key = Object.keys(item)[0];
                result[key] = item[key];
                return result;
            }, {});
            this.defaultGridColumns = resultValues;
            this.invMetricColumns.loadAllValues(this.getOptionalMetricsColumns());
            this.invMetricColumns.selectedValues = JSON.parse(localStorage.getItem('invGridSelectedColumns'));
        }, error => {
            console.log(`Error retrieving default grid columns: ${error}`);
        });

    }

    public sortChange(sort: Array<SortDescriptor>) {
        this.gridSort = sort;
        this.selectedRows = [];
        this.gridData = orderBy(this.gridData, this.gridSort);
    }

    onInventoryFlagChange(selectedInvFl: any) {
        this.selectedInvFlag = selectedInvFl;
    }

    toggleColumn(evt: MouseEvent, field: any) {
        evt.preventDefault();
        this.showCompPriceDiff = !this.showCompPriceDiff;
    }

    private getTubeData(): void {
        this.tubeChartLoading = true;
        this.selectedShoulderTrainData = null;
        this.shoulderChartRequest = null;
        this.gridData = null;
        this.totalData = null;
        this.capacityChartData = null;
        this.allowReOpt = this.searchRequest.portfolio.length > 0;
        this.inventoryService.getTubeMapData(this.searchRequest).subscribe(data => {
                if (!this.inventoryFlags.selectedValues.id) {
                    this.inventoryFlags.selectedValues = this.inventoryFlags.findById('capacity_constraints');
                    this.selectedInvFlag = this.inventoryFlags.selectedValues;
                }
                this.northTube = data.north;
                this.southTube = data.south;
                this.tubeChartLoading = false;
            },
            error => {
                this.tubeChartLoading = false;
            });
    }

    private getTubeInfo(tubeRequest: any): any {
        const filterFn = (t) => t.rsid === tubeRequest.rsid
            && t.departureDate === tubeRequest.departureDate;
        if (this.currentChart === 'northBound') {
            return this.northTube.tubes.find(filterFn);
        } else {
            return this.southTube.tubes.find(filterFn);
        }
    }

    private onFilterExpand(isExpanded: boolean): void {
        this.isFilterExpanded = isExpanded;
    }

    private onHeatmapMenuClick(event: SharedChartMenuEvent): void {
        if (event.action === SharedChartMenuAction.viewDetails ||
            event.action === SharedChartMenuAction.viewODs) {
            this.getGridData(event.data);
            this.getShoulderChart(event.data);
            this.getCapacityChart(event.data);
        } else if (event.action === SharedChartMenuAction.overrideAdvanced) {
            const overrideLvl = event.data.origin === '' || event.data.origin === undefined ?
                OverrideLevel.Train : OverrideLevel.Leg;
            this.displayBulkOverride(overrideLvl, event.data);
        } else if (event.action === SharedChartMenuAction.toggleAutoPilot) {
            this.enableAutoPilot(event.data);
        } else if (event.action === SharedChartMenuAction.overrideWalkUp) {
            this.displayWalkUpDialog(this.buildWalkUpRequest(event.data));
        } else {
            console.error('Unknown heatmap action');
        }
    }

    public onValueChange(value) {
     // SidebarNavComponent.selectedInventoryGridColumns = value;
     localStorage.setItem('selectedInventoryGridColumns', JSON.stringify(value));
    }

    public getSelectedValues() {
        return JSON.parse(localStorage.getItem('invGridSelectedColumns'));
    }

    // This method checks if a column is supposed to be hidden or displayed by default. Used for initial load
    // when HTML page loads soon than the defaultGridColumns
    public getDefaultGridColumnVal(value: string) {
        if (value in this.defaultGridColumns) {
             return !this.defaultGridColumns[value].default;
        }
        return false;
    }
}
