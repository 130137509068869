import {AfterViewInit, Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {Align} from '@progress/kendo-angular-popup';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements AfterViewInit {
    @ViewChild('popup', {read: ElementRef}) private popup: ElementRef;
    public show = false;
    public anchorAlign: Align = {horizontal: 'right', vertical: 'bottom'};
    public popupAlign: Align = {horizontal: 'left', vertical: 'bottom'};
    public userInitials = '';
    public profilePicUrl = '';

    constructor(public elRef: ElementRef, private authService: AuthService) {
        this.userInitials = this.authService.getUserInitials();
        this.profilePicUrl = this.authService.getProfilePic();
    }

    ngAfterViewInit(): void {
        // @ts-ignore
        window?.zE('webWidget', 'hide');
        // @ts-ignore
        window?.zESettings = {
            webWidget: {
                offset: {horizontal: '90px', vertical: '20px'}
            }
        };
        // @ts-ignore
        window?.zE('webWidget:on', 'close', () => {
            // @ts-ignore
            window?.zE('webWidget', 'hide');
        });
    }

    public logout(): void {
        this.authService.logout();
    }

    public onToggle(show?: boolean): void {
        this.show = show !== undefined ? show : !this.show;
    }

    public showSupport(): void {
        // @ts-ignore
        window?.zE('webWidget', 'show');
        // @ts-ignore
        window?.zE('webWidget', 'open');
    }

    public userMenuItemClicked(event: any): void {
        if (event.index === '0') {
            this.logout();
        } else if (event.index === '1') {
            // @ts-ignore
            window?.zE('webWidget', 'show');
            // @ts-ignore
            window?.zE('webWidget', 'open');
        }
    }

    @HostListener('document:click', ['$event'])
    private documentClick(event: any): void {
        if (!this.popupContains(event.target)) {
            this.onToggle(false);
        }
    }

    private popupContains(target: any): boolean {
        return this.elRef.nativeElement.parentElement.contains(target) ||
            (this.popup ? this.popup.nativeElement.contains(target) : false);
    }
}
